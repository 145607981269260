import { useState } from "react";
import { createContext } from "react";
import Login from "./Login";
import OTPInput from "./ForgotPassword";
import Recovered from "./Recovered";
import Reset from "./ResetPassword";

export const RecoveryContext = createContext();
function Auth() {
  const [page, setPage] = useState("login");
  const [email, setEmail] = useState('');
  const [otp, setOTP] = useState('');

  function NavigateComponents() {
    if (page === "login") return <Login />;
    if (page === "otp") return <OTPInput />;
    if (page === "reset") return <Reset />;

    return <Recovered />;
  }

  return (
    <RecoveryContext.Provider
      value={{ page, setPage, otp, setOTP, setEmail, email }}
    >
      <div>
        <NavigateComponents />
      </div>
    </RecoveryContext.Provider>
  );
}

export default Auth;
