import React from 'react';

import { PhoneIcon, ArrowSmRightIcon } from '@heroicons/react/outline';
import {ChipIcon, SupportIcon} from '@heroicons/react/solid'

import supportImg from '../assets/support.jpg'

const Support = () => {
  return (
  <div name='support' className='w-full mt-24'>
      <div className='w-full h-[700px] bg-gray-900/90 absolute'>
        <img className='w-full h-full object-cover mix-blend-overlay' src={supportImg} alt="/" />
      </div>
      
      <div className='max-w-[1240px] mx-auto text-white relative'>
          <div className='px-4 py-12'>
              <h2 className='text-3xl pt-8 text-slate-300 uppercase text-center share-tech-regular'>Support</h2>
              <h3 className='text-2xl md:text-5xl font-bold py-6 text-center font-sans'>Finding The Right Team</h3>
                  <p className='py-4 text-2xl text-slate-300'>Meet our exceptional Support Team - a group of dedicated experts who are here to ensure your technology experience is seamless and extraordinary. With a passion for problem-solving and a wealth of technical knowledge, our team stands ready to assist you every step of the way.</p>
          </div>
          <div className='grid grid-cols-1 lg:grid-cols-3 relative gap-x-8 gap-y-16 px-4 pt-12 sm:pt-20 text-black'>
              <div className='bg-white rounded-xl shadow-2xl'>
                  <div className='p-8'>
                      <PhoneIcon className='w-16 p-4 bg-blue-500 text-white rounded-lg mt-[-4rem]' />
                      <h3 className='font-bold text-2xl my-6 text-gray-900'>Sales</h3>
                          <p className='text-gray-600 text-xl'>With a strong focus on customer satisfaction and a keen eye for identifying potential opportunities, our sales team is the backbone of our company's growth
                              through their tireless efforts and unwavering commitment to excellence by working closely with our clients to undestand their evolving needs and preferences.</p>
                  </div>
                  <div className='bg-slate-100 pl-8 py-4'>
                      <a className='flex items-center text-blue-500' target="blank" href="mailto:sales@skyfalke.com">Contact Us <ArrowSmRightIcon className='w-5 ml-2' /></a>
                  </div>
              </div>
              <div className='bg-white rounded-xl shadow-2xl'>
                  <div className='p-8'>
                      <SupportIcon className='w-16 p-4 bg-blue-500 text-white rounded-lg mt-[-4rem]' />
                      <h3 className='font-bold text-2xl my-6 text-gray-900'>Technical Support</h3>
                          <p className='text-gray-600 text-xl'>Our dedicated group of expacts are committed to providing exceptional customer service and solving any technical issues that may arise. With a deep understanding of our company's products and services, this team is equipped with the knowledge and tools necessary to assist
                              customers with any technical problems they may encounter.</p>
                  </div>
                  <div className='bg-slate-100 pl-8 py-4'>
                      <a className='flex items-center text-blue-600' target="blank" href="mailto:support@skyfalke.com">Contact Us <ArrowSmRightIcon className='w-5 ml-2' /></a>
                  </div>
              </div>
              <div className='bg-white rounded-xl shadow-2xl'>
                  <div className='p-8'>
                      <ChipIcon className='w-16 p-4 bg-blue-500 text-white rounded-lg mt-[-4rem]' />
                      <h3 className='font-bold text-2xl my-6 text-gray-900'>Media Inquiries</h3>
                          <p className='text-gray-600 text-xl'>We understand the importance of media coverage in today's fast-paced world, and we are committed to helping journalists, editors,
                              and other media professionals get the information they need, So, if you're a member of the media and you're interested in learning more about our company, please don't hesitate to get in touch with us.</p>
                  </div>
                  <div className='bg-slate-100 pl-8 py-4'>
                      <a className='flex items-center text-blue-600' target="blank" href="mailto:press@skyfalke.com">Contact Us <ArrowSmRightIcon className='w-5 ml-2' /></a>
                  </div>
              </div>
          </div>
      </div>
  </div>
  );
};

export default Support;
