import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { baseURL } from '../utils/constant';
import logo from '../assets/logo3.png';
import { FaSpinner } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import { useContext } from "react";
import { RecoveryContext } from "./Auth";
/*import { FcGoogle } from "react-icons/fc"; */
/*import  GoogleLogin  from 'react-google-login' */


/*const clientId="1051543273039-5sn0j3hd7abtu22fbq364bcf6tmllcai.apps.googleusercontent.com"; */

/*const GoogleSignInButton = () => {
  const handleFailure = (error) => {
    alert(error);
  };

  const handleGoogleSignIn = async (googleData) => {
    try {
      const tokenId = googleData.tokenId;
      const res = await axios.post(`${baseURL}/google-login`, { token: tokenId });
      if(res.data.status === "ok"){
        window.localStorage.setItem('loggedIn', 'true');
        window.localStorage.setItem('token', res.data.token);
        toast.success('Login successful!', {
          position: toast.POSITION.TOP_CENTER
        });
        window.location.href = '/';
      } else {
        toast.success('Google SignIn Failure!', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    } catch (error) {
      console.error('Google Sign-In failed:', error);
       toast.error('Login failed. Please try again.', {
        position: toast.POSITION.TOP_CENTER
      });
    }
  };

  return (
    <GoogleLogin
      clientId={clientId}
      buttonText="Sign In with Google"
      onSuccess={()=>handleGoogleSignIn}
      onFailure={()=>handleFailure}
      cookiePolicy={'single_host_origin'}
      isSignedIn={true}
      render={renderProps => (
        <div onClick={renderProps.onClick} className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-blue-100 hover:cursor-pointer">
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-navy-700 dark:text-white">Sign In with Google</h5>
        </div>
      )}
    />
  );
}; */


const SignInPage = () => {
  const { setEmail, setPage, email, setOTP } = useContext(RecoveryContext);
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  
  function nagigateToOtp() {
    if (email) {
      if (!/\S+@\S+\.\S+/.test(email)) {
        return  toast.error("Enter valid email address.", {
          position: toast.POSITION.TOP_CENTER
        });
      }
      const OTP = Math.floor(Math.random() * 9000 + 1000);
      console.log(OTP);
      setOTP(OTP);

      axios
        .post(`${baseURL}/send_recovery_email`, {
          OTP,
          recipient_email: email,
        })
        .then(() => setPage("otp"))
        .catch(console.log);
      return;
    }
    return  toast.error("Please enter your registered email", {
      position: toast.POSITION.TOP_CENTER
    });
  }


  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    //Validations
    const validationErrors = {};
    if (!email) {
        validationErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
        validationErrors.email = 'Invalid email address';
    }
    if (!password) {
        validationErrors.password = 'Password is required';
    } else if (password.length < 8) {
        validationErrors.password = 'Password must be at least 8 characters long';
    }

    if (Object.keys(validationErrors).length === 0) {
        // Form is valid, submit data to the server
        const userData = {
            email,
            password
        }
        try {
            await axios.post(`${baseURL}/signin`, userData)
                .then((response) => {
                    if (response.data.status === "ok") {                        
                        window.localStorage.setItem("token", response.data.data);
                        window.localStorage.setItem("loggedIn", "true"); 
                        toast.success('Login successful!', {
                          position: toast.POSITION.TOP_CENTER
                        });
                        setTimeout(() => {
                            setLoading(false);
                        }, 2000);                        
                        window.location.href = '/';
                    } else if (response.data.status === "notFound") {                        
                      toast.error("Account with that email was not found", {
                        position: toast.POSITION.TOP_CENTER
                      });
                      setTimeout(() => {
                          setLoading(false);
                      }, 2000); 
                  } else if (response.data.status === "invalidPassword") {                        
                    toast.error("Invalid Password", {
                      position: toast.POSITION.TOP_CENTER
                    });
                    setTimeout(() => {
                        setLoading(false);
                    }, 2000);
                } else {
                      toast.error(`${response.data.error}`, {
                        position: toast.POSITION.TOP_CENTER
                      });
                        setLoading(false);
                    }
                });
            setErrors({});
        }
        catch (e) {
            if (e.code === "ERR_NETWORK") {
              toast.error('It seems you not connected to Internet.', {
                position: toast.POSITION.TOP_CENTER
              });
            }
            setLoading(false);
        }
    } else {

        setErrors(validationErrors);
        setLoading(false);
    }
}

  return (
    <>
    <ToastContainer />
    <div className="min-h-screen bg-slate-900">
      <nav className="w-full flex items-center justify-between px-4 py-5 bg-transparent top-0 fixed">
        <div>
            <Link to='/'><img src={logo} alt="Logo" className="h-8 md:h-10" /></Link>
        </div>
      </nav>
      <div className="flex items-center justify-center min-h-screen mx-auto px-2">
        <div className="max-w-md w-full p-6 bg-white rounded-md shadow-lg">
          <h2 className="mb-2.5 text-xl md:text-3xl font-bold text-navy-700 dark:text-white">Sign In</h2>
          <p className="mb-5 ml-1 text-sm md:text-base text-gray-400">Enter your email and password to sign in!</p>
          {/* <div>
            <GoogleSignInButton />
          </div>
          <div className="mb-3 md:mb-6 flex items-center  gap-3">
            <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
            <p className="text-base text-gray-600 dark:text-white"> or </p>
            <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          </div>          */}
          <form onSubmit={handleSubmit}>
            <div className="mb-3 md:mb-4">
              <label className="block mb-2 font-semibold text-navy-700 text-navy-700 " htmlFor="email" >Email*</label>
              <input
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                type="email"
                id='email'
                name='name'
                autoComplete='true'
                value={email}
                placeholder="mail@example.com"
                onChange={handleEmailChange}
                required
                autoFocus
              />
              {errors.email && <span className="text-red-500">{errors.email}</span>}
            </div>
            <div className="mb-3 md:mb-4">
              <label className="block mb-2 font-semibold" htmlFor="password">Password*</label>
              <input
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Min. 8 characters"
                required
              />
              {errors.password && <span className="text-red-500">{errors.password}</span>}
            </div>
            <div className="mt-4 text-right">
              <button onClick={() => nagigateToOtp()} className="text-blue-500 bg-transparent border-none hover:text-blue-700 hover:bg-transparent">Forgot Password?</button>
            </div>
            <div className="flex justify-center">
              <button
                className="linear mt-2 w-full rounded-xl bg-yellow-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-yellow-600 hover:text-white active:bg-blue-700"
                type="submit"
                disabled={loading} 
              >
                 {loading && (
                        <FaSpinner className='animate-spin mx-auto' />
                    )}
                    {!loading && 'Sign In'}
              </button>
            </div>
          </form>    
       
          <div className="mt-4">
            <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
              Not registered yet?
            </span>
            <Link to="/signup" className="ml-1 text-sm font-medium text-blue-500 hover:text-blue-600 dark:text-white">Sign Up</Link>
          </div>          
        </div>
      </div>
    </div>
    </>
  );
};

export default SignInPage;
