import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo2.png';
import { useContext } from "react";
import { RecoveryContext } from "./Auth";


const Recovered = () => {
  const { email } = useContext(RecoveryContext);

  return (
    <div className="min-h-screen bg-gray-100">
      <nav className="w-full flex items-center justify-between px-4 py-5 bg-transparent top-0 fixed">
        <div>
            <Link to='/'><img src={logo} alt="Logo" className="h-8" /></Link>
        </div>
      </nav>
      <div className="flex items-center justify-center min-h-screen mx-auto px-2">
        <div className="max-w-md w-full p-6 bg-white rounded-md shadow-lg">
          <p className='text-green-400 font-bold'>Password for account with the email {email} was changed successfully! You can <Link to='signin'>Log in</Link> to continue.</p>
        </div>
      </div>
    </div>
  );
};

export default Recovered;
