import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { PropagateLoader } from 'react-spinners';
import { css } from '@emotion/react';

import logo1 from '../webimages/liquid.png'; 
// import logo2 from '../webimages/lowland.png';
import logo3 from '../webimages/bhogals.PNG';

const ClientLogos = () => {
  const clientLogo = [
    { logo: logo1 },
    // { logo: logo2 },
    { logo: logo3 },
  ];

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <section className="py-12 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-6 share-tech-regular">Our Clients and Partners</h2>        
        {clientLogo.length === 0 ? (
          <div className="flex justify-center items-center h-64">
            <PropagateLoader color="#4A90E2" css={override} size={10} /> 
          </div>
        ) : (
          <Slider
            dots={true}
            infinite={true}
            speed={500}
            slidesToShow={2}
            slidesToScroll={1}
            autoplay={true}
            autoplaySpeed={2000}
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                },
              },
            ]}
          >
            {clientLogo.map((client, index) => (
              <div key={index} className="p-6">
                <img src={client.logo} alt="Client logo" className="object-contain h-32" />
              </div>
            ))}
          </Slider>
        )}
      </div>
    </section>
  );
};

export default ClientLogos;
