import React from 'react'

export const Press = () => {
    /*const cards = [
        { name: 'Suheil Juneid', title: 'Media Relations Manager', email: 'juneid@skyfalke.com', phone: '0713139854' }
      ]; */
  return (
    <div name='platforms' className='w-full my-32'>
      <div className='max-w-[1240px] mx-auto px-2 mt-10 text-xl text-left'>
        <div className='mt-4'>
            <h2 className='font-bold'>Company Overview</h2>
            <p className='mt-3'>SkyFalke is a leading technology company at the forefront of innovation and cutting-edge solutions. Founded in 2023, we specialize in software development, artificial intelligence, cloud solutions, cyber security, Data analytics & business intelligence, graphics design and digital design. Our team of skilled professionals is dedicated to delivering high-quality products and services that empower businesses and individuals to achieve their full potential.</p>
        </div>
        <div className='mt-4'>
            <h2 className='font-bold'>Mission Statement</h2>
            <p className='mt-3'>To empower individuals and industries by providing cutting-edge technology solutions that enhance their day-to-day lives.</p>
        </div>
        <div className='mt-4'>
            <h2 className='font-bold'>Key Products and Services</h2>
            <ol>
                <li>SalesFlow ERP:  SalesFlow is a revolutionary software product that transforms how businesses manage their inventory, track sales, and handle payments. With its intuitive interface and advanced features, SalesFlow simplifies inventory management, enables real-time sales tracking, and supports seamless payment processing. By automating tasks and providing valuable insights, SalesFlow empowers businesses to optimize their operations, enhance customer satisfaction, and drive growth. Experience the future of inventory management with SalesFlow and unlock your business's full potential.</li>
            </ol>
        </div>
        <div className='mt-4'>
            <h2 className='font-bold'>Achievements</h2>
            <div className='mt-4'>
                <h2><a href="https://www.lowland.com" className='text-blue-800 underline'>Lowland Vegetables Comp.</a></h2>
                <p>We are thrilled to announce our groundbreaking partnership with Lowland Vegetables, aimed at revolutionizing their business operations and catapulting them into a new era of efficiency and productivity. By harnessing the power of technology, we are creating a state-of-the-art CRM system tailored specifically to Lowland Vegetables' needs, enabling them to seamlessly manage stocks, sales, and employees like never before.
                    With our cutting-edge CRM solution, Lowland Vegetables will gain unparalleled control over their inventory, streamlining stock management, and ensuring optimal supply chain efficiency. Real-time updates, intuitive dashboards, and automated alerts will empower their team to make data-driven decisions and eliminate costly bottlenecks. Say goodbye to manual stock tracking and embrace a new level of accuracy and precision.</p>
            </div>
        </div>
        {/*<div className='grid mt-5'>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {cards.map((card, index) => (
            <div key={index} className="bg-white rounded-md shadow-lg max-w-md w-full p-6">
            <h3 className="text-xl font-semibold mb-2">{card.name}</h3>
            <p>{card.title}</p>
            <p>{card.email}</p>
            <p>{card.phone}</p>
            </div>
            ))}
          </div>
      </div> */}
    </div>
    </div>
  )
}

export default Press;
