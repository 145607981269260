import React from 'react';
import { Link } from 'react-router-dom';
import { HomeIcon } from '@heroicons/react/solid'; 
import { MdChromeReaderMode, MdPeopleAlt, MdMarkAsUnread, MdOutlineShoppingCart } from 'react-icons/md';
import  logo from '../../assets/logo2.png';


const AdminSidebar = () => {
    
   return (
    <div className="h-screen flex overflow-hidden relative">
      <div className="w-64 bg-gray-800 text-white">
        <div className="flex items-center justify-between p-4">
          <a href='../'><img src={`${logo}`} alt='logo' />  </a>        
        </div>
        <nav className="px-3 mt-6">
          <Link to="./"  className="flex items-center  px-4 py-4 text-l font-medium hover:bg-gray-700">
            <HomeIcon className="w-6 h-6 mr-2" /> Home
          </Link>
          <Link  to="./blogs" className="flex items-center px-4 py-4 mt-1 text-l font-medium hover:bg-gray-700">
            <MdChromeReaderMode className="w-6 h-6 mr-2" /> Blogs
          </Link>
          <Link  to="./solutions"   className="flex items-center px-4 py-2 mt-1 text-l font-medium hover:bg-gray-700">
            <MdOutlineShoppingCart className="w-6 h-6 mr-2" /> Solutions
          </Link>
          <Link to="./clients"  className="flex items-center px-4 py-4 mt-1 text-l font-medium hover:bg-gray-700">
            <MdPeopleAlt className="w-6 h-6 mr-2" /> Clients
          </Link>
          <Link to="./newsletter-subscribers"  className="flex items-center px-4 py-4 mt-1 text-l font-medium hover:bg-gray-700">
            <MdMarkAsUnread className="w-6 h-6 mr-2" /> Newsletter Subscribers
          </Link>
        </nav>
      </div>    
    </div>
  )
}
export default AdminSidebar;
