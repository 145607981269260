import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { PropagateLoader } from 'react-spinners'; // Import the loader component
import { css } from '@emotion/react'; // Import CSS utility
import { baseURL } from '../utils/constant';
import { Link } from 'react-router-dom';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`; 

const LatestNews = () => {
  const [latestNews, setLatestNews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getNews() {
      try {
        const response = await axios.post(`${baseURL}/fetchLatestBlogs`);
        setLatestNews(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching latest blogs:', error);        
      }
    }
    getNews();
  }, []);

  return (
    <section className="bg-gray-100 py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8 share-tech-regular">What's Happening?</h2>
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <PropagateLoader color="#4A90E2" css={override} size={15} /> 
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {latestNews && (
              latestNews.map((item) => (
                <div key={item._id} className="bg-white rounded-lg shadow-md">
                  <img
                    src={require(`../webimages/${item.thumbnail}`)}
                    alt={item.title}
                    className="w-full h-48 object-cover rounded-t-lg"
                  />
                  <div className="p-4">
                    <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                    <p className="text-gray-600 text-sm mb-4">
                      {format(new Date(item.createdAt), 'MMMM dd, yyyy')}
                    </p>
                    <Link
                      to={`/blog/${item._id}`}
                      className="text-blue-500 hover:text-blue-600 font-semibold text-sm"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default LatestNews;
