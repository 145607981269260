import React from 'react';

const PrivacyPage = () => {
  return (
    <div className="container mx-auto w-full my-32 text-xl px-2">
      <h1 className="text-2xl md:text-4xl font-bold text-center mt-10 mb-12">Privacy Policy</h1>
      <p className="text-gray-700">
        At SkyFalke, we take your privacy seriously. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our website and services.
      </p>
      <h2 className="text-2xl font-semibold mt-8 mb-4">Information We Collect</h2>
      <p className="text-gray-700">
        When you visit our website, we may collect certain information automatically, including your IP address, browser type, and usage data. We may also collect personal information that you voluntarily provide to us through contact forms or when you subscribe to our newsletter.
      </p>
      <h2 className="text-2xl font-semibold mt-8 mb-4">How We Use Your Information</h2>
      <p className="text-gray-700">
        We may use the information we collect to improve our website, personalize your experience, provide customer support, send periodic emails, and analyze user trends and preferences. We do not sell, trade, or rent your personal information to third parties.
      </p>
      <h2 className="text-2xl font-semibold mt-8 mb-4">Data Security</h2>
      <p className="text-gray-700">
        We implement appropriate security measures to protect your personal information from unauthorized access and disclosure. However, no data transmission over the internet or electronic storage is completely secure, so we cannot guarantee absolute security.
      </p>
      <h2 className="text-2xl font-semibold mt-8 mb-4">Third-Party Links</h2>
      <p className="text-gray-700">
        Our website may contain links to third-party websites. We are not responsible for the privacy practices or the content of those websites. We encourage you to review the privacy policies of any third-party websites you visit.
      </p>
      <h2 className="text-2xl font-semibold mt-8 mb-4">Changes to This Privacy Policy</h2>
      <p className="text-gray-700">
        We reserve the right to update or change our Privacy Policy at any time. Any changes will be effective immediately upon posting on this page. We encourage you to review this Privacy Policy periodically to stay informed about how we collect, use, and protect your personal information.
      </p>
    </div>
  );
};

export default PrivacyPage;
