import React from 'react';

const CloudPage = () => {
  return (
    <div className="container mx-auto my-32 text-lg max-w-[1240px]">
      <h1 className="text-4xl font-bold text-center mt-10 mb-12">Cloud Solutions</h1>
      <p className="text-center text-gray-700 mb-8">
        We offer a range of powerful and scalable cloud solutions that enable businesses to harness the full potential of the cloud.
        Our cloud services provide flexibility, security, and reliability, allowing organizations to optimize their operations and drive innovation.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <h2 className="text-2xl font-semibold mb-4">Cloud Hosting</h2>
          <p className="text-gray-700 mb-4">
            Our cloud hosting services provide a robust infrastructure for your applications and data, ensuring high availability and performance.
            With scalable resources and 24/7 monitoring, your business can easily adapt to changing demands and deliver a seamless user experience.
          </p>
          <ul className="list-disc list-inside">
            <li>Reliable and secure hosting environment</li>
            <li>Scalable resources based on your needs</li>
            <li>24/7 monitoring and support</li>
            <li>High availability and uptime</li>
          </ul>
        </div>
        <div>
          <h2 className="text-2xl font-semibold mb-4">Cloud Storage</h2>
          <p className="text-gray-700 mb-4">
            Our cloud storage solutions provide a secure and cost-effective way to store, backup, and access your data.
            With flexible storage options and built-in redundancy, your data remains protected and accessible from anywhere, at any time.
          </p>
          <ul className="list-disc list-inside">
            <li>Scalable storage capacity</li>
            <li>Secure data encryption</li>
            <li>Automated backups and data recovery</li>
            <li>Easy access and collaboration</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CloudPage;
