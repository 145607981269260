import React, { useState } from 'react';
import axios from 'axios';
import { baseURL } from '../utils/constant';
import { FaSpinner } from 'react-icons/fa';
import RotatingText from 'react-rotating-text';
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';


const rotatingTextOptions = {
  items: ['Ready To Get Started?','Contact Us Now','We Are Waiting For You'],
  typingInterval: 100,
  deletingInterval: 50,
  pauseInterval: 2500,
};

const ContactForm = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [solution, setSolution] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  
  const handleCallToAction = async(e) => {

    e.preventDefault();
    setLoading(true);

    const validationErrors = {};
    if (!email) {
        validationErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
        validationErrors.email = 'Invalid email address';
    }
    if (!name) {
        validationErrors.name = 'Name is required';
    } 
    if (!solution) {
    validationErrors.solution = 'Please choose a solution';
  } 
    if (Object.keys(validationErrors).length === 0) {
        // Form is valid, submit data to the server
        const payload = {
            email,
            name,
            message,
            solution
        }
        try {
          const res = await axios.post(`${baseURL}/call-to-action`, payload );
          if(res.data.status === "ok"){
            setLoading(false);
            toast.success('We received your request :) we will contact you shortly.', {
              position: toast.POSITION.TOP_CENTER
            });
            setEmail('');
            setName('');
            setMessage('');
            setSolution('');
          }
        } catch (error) {
          setLoading(false);
          toast.error('Request was not sent :( Please try again.', {
            position: toast.POSITION.TOP_CENTER
          });
          console.log(error);
        }
    } else {
        setErrors(validationErrors);
        setLoading(false);
    }
  }


  return (
    <>
    <ToastContainer />
    <div className="container mx-auto w-full my-32">
      <div className="flex flex-col md:flex-row mx-auto px-2 mt-10 text-xl">
        <div className="md:w-1/2">
          <h2 className="text-xl font-semibold mb-4">Get in Touch</h2>
          <p className="mb-4">Have questions or feedback? Reach out to us using the contact information below:</p>
          <ul className="mb-8">
            <li className="flex items-center mb-4">
              <svg className="w-6 h-6 mr-2" fill="currentColor" viewBox="0 0 24 24">
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8 0-4.41 3.59-8 8-8 4.41 0 8 3.59 8 8 0 4.41-3.59 8-8 8zm0-14c-3.31 0-6 2.69-6 6 0 1.63.64 3.13 1.67 4.24L12 19l4.33-4.76C17.36 13.13 18 11.63 18 10c0-3.31-2.69-6-6-6zm0 10c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z"></path>
              </svg>
              <span>Email: info@skyfalke.com</span>
            </li>
            <li className="flex items-center">
              <svg className="w-6 h-6 mr-2" fill="currentColor" viewBox="0 0 24 24">
                <path d="M12 1L3 5v14l9 4 9-4V5l-9-4zm0 2.7L19.6 6 12 8.8 4.4 6 12 3.3zM5 16V8.45l7 3.11 7-3.11V16l-7 3-7-3zm9-5h2v2h-2v-2zm-4 0h2v2H10v-2z"></path>
              </svg>
              <span>Phone: +254-7134-90657</span>
            </li>
          </ul>
        </div>
        <div className="md:w-1/2">
          <h2 className="text-xl font-semibold mb-4">Send Us An Equiry</h2>
          <form onSubmit={handleCallToAction}>
            <div className="mb-4">
              <label className="block mb-2 font-semibold" htmlFor="name">Name</label>
              <input className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" type="text" value={name} onChange={(e)=>setName(e.target.value)} required />
              {errors.name && <span className="text-red-300">{errors.name}</span>}
            </div>
            <div className="mb-4">
              <label className="block mb-2 font-semibold" htmlFor="email">Email</label>
              <input className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" type="email" value={email} onChange={(e)=>setEmail(e.target.value)} required />
              {errors.email && <span className="text-red-300">{errors.email}</span>}
            </div>
            <div className="mb-4">
              <label className="block mb-2 font-semibold" htmlFor="name">Service/Product</label>
              <select className='w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500' value={solution} onChange={(e)=>setSolution(e.target.value)}>
                <option value="Custome Software development">Custome Software development</option>
                <option value="Cyber Security">Cyber Security</option>
                <option value="Cloud solutions & Integration">Cloud solutions & Integration</option>
                <option value="Data analysis & Business Intelligence">Data Analysis & Business Intelligence</option>
                <option value="Marketing">Marketing</option>
                <option value="Graphics & Digital Design">Graphics & Digital Design</option>
                <option value="Our Products Line">Our Products Line</option>
                <option value="Other">Other</option>
              </select>
              {errors.solution && <span className="text-red-300">{errors.solution}</span>}
            </div>
            <div className="mb-4">
              <label className="block mb-2 font-semibold" htmlFor="message">Specify Your Enquiry</label>
              <textarea className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" value={message} onChange={(e)=>setMessage(e.target.value)} rows="4" required></textarea>
            </div>
            <button className="px-6 py-2 shadow-lg bg-yellow-500 border-none text-white rounded-md focus:outline-none" type="submit"> {loading && (
                  <FaSpinner className='animate-spin mx-auto' />
              )}
              {!loading && 'Submit'}</button>
          </form>
        </div>
      </div>
    </div>
    </>
  );
};
const CallToAction = () => {
 
  const [showContactForm, setShowContactForm] = useState(false);

  return (
    <section className="bg-gray-100 py-12">
      <div className="container mx-auto px-4 w-full">
        <div className="max-w-3xl mx-auto text-center">
          <h1 className="text-4xl font-bold mb-4 font-mono">
            <RotatingText
                items={rotatingTextOptions.items}
                typingInterval={rotatingTextOptions.typingInterval}
                deletingInterval={rotatingTextOptions.deletingInterval}
                pauseInterval={rotatingTextOptions.pauseInterval}
              />
          </h1>
          <p className="text-2xl py-6 text-gray-500 mb-8">
            Contact us today and discover how our services and solutions can benefit you and your business.
          </p>
          <button
            className="bg-yellow-500 border-none shadow-lg text-white px-10 py-3 rounded-full transition-colors duration-300 focus:outline-none"
            onClick={() => {
              setShowContactForm(true);
            }}
          >
            Contact Us
          </button>
        </div>
      </div>
      {showContactForm && <ContactForm/>}
    </section>
  );
};

export default CallToAction;
