import React from 'react';
import PrintingMachineImage from '../assets/3DPrinter.jpg';

const PrintingService = () => {
  const handleRequestQuotation = () => {
    const email = 'info@skyfalke.com';
    const subject = encodeURIComponent('Request for Quotation');
    const body = encodeURIComponent('Please provide me with a quotation for your 3D printing service.');

    window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
  };

  return (
    <div className="bg-gray-100 rounded-lg shadow-md flex flex-col md:flex-row items-center container my-32 w-full mx-auto p-10">
      <div className="md:w-1/2 mb-4 md:mb-0">
        <img src={PrintingMachineImage} alt="3D Printer" className="w-full h-auto rounded-md" />
      </div>
      <div className="md:w-1/2 md:ml-6">]
        <h2 className="text-2xl share-tech-regular  mb-4">3D Printing Service</h2>
        <p className="text-gray-700 mb-4">
          We offer high-quality 3D printing services for prototypes, models, and custom designs. Whether you're a hobbyist or a business, we can bring your ideas to life.
        </p>
        <ul className="list-disc pl-5 mb-4">
          <li>Fast turnaround time</li>
          <li>Wide range of materials available</li>
          <li>Competitive pricing</li>
          <li>Expert support</li>
        </ul>
        <button
          onClick={handleRequestQuotation}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Request a Quotation
        </button>
      </div>
    </div>
  );
};

export default PrintingService;
