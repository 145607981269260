import React from 'react';

const ConditionsPage = () => {
  return (
    <div className="container mx-auto my-32 text-lg w-full px-2">
      <h1 className="text-2xl md:text-4xl font-bold text-center mt-10 mb-12">Conditions</h1>
      <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
      <p className="text-gray-700">
        These conditions govern your use of our website and services. By accessing or using our website,
        you agree to comply with and be bound by these terms and conditions. If you do not agree with any part of these terms, please refrain from
        using our website.
      </p>

      <h2 className="text-2xl font-semibold mb-4">2. Use of the Website</h2>
      <p className="text-gray-700">
        The content of our website is for general information and promotional purposes only. We strive to provide accurate and up-to-date information,
        but we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or
        availability of the website or the information, products, services, or related graphics contained on the website. Your use of any information
        or materials on our website is entirely at your own risk.
      </p>

      <h2 className="text-2xl font-semibold mb-4">3. Account Creation</h2>
      <p className="text-gray-700">
        In order to access certain features or services on our website, you may need to create an account. You are responsible for maintaining the
        confidentiality of your account credentials and for all activities that occur under your account. You agree to provide accurate and complete
        information when creating your account and to promptly update any information to keep it accurate and current.
      </p>

      <h2 className="text-2xl font-semibold mb-4">4. Privacy Policy</h2>
      <p className="text-gray-700">
        Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use, and disclose information related to your
        use of our website and services. By using our website, you consent to the collection and use of your information as described in our Privacy
        Policy.
      </p>

      <h2 className="text-2xl font-semibold mb-4">5. Third-Party Links</h2>
      <p className="text-gray-700">
        Our website may contain links to third-party websites or resources. These links are provided for your convenience and do not signify our
        endorsement of the content on those websites. We have no control over the nature, content, and availability of third-party websites, and we
        are not responsible for any damages or losses caused by or in connection with your use of such websites.
      </p>

      <h2 className="text-2xl font-semibold mb-4">6. Termination</h2>
      <p className="text-gray-700">
        We reserve the right to suspend or terminate your access to our website and services at any time, without prior notice or liability, for any
        reason whatsoever, including without limitation if you breach these terms and conditions.
      </p>

      <h2 className="text-2xl font-semibold mb-4">7. Contact Us</h2>
      <p className="text-gray-700">
        If you have any questions or concerns about these terms and conditions, please contact us at <span className='italic font-mono'>legals@skyfalke.com</span>. We will be happy
        to assist you and address any issues you may have.
      </p>

      <p className="text-gray-700 font-mono">
        Last updated: 31st January 2024
      </p>
    </div>
  );
};

export default ConditionsPage;
