import React from 'react';

const About = () => {
  return (
    <div name='platforms' className='w-full my-32'>
      <div className='max-w-[1240px] mx-auto px-2'>
        <h2 className='text-4xl font-bold font-sans'>About Us</h2>
        <div className='mt-10 text-xl text-left'>        
          <p className='mt-6'>
            We are passionate about leveraging cutting-edge technology to empower individuals and businesses alike. Our mission is to provide innovative solutions that transform the way people live, work, and interact in the digital age.
          </p>
          <div className='mt-4'>
            <h2 className='font-bold'>Our Expertise:</h2>
            <ol>
                <li>1. <span className='font-bold'>Software Development:</span> We specialize in developing custom software solutions tailored to meet the unique needs of our clients. Whether it's web applications, mobile apps, or enterprise software, our talented team of developers crafts robust, scalable, and intuitive software products.</li>
                <li>2. <span className='font-bold'>Artificial Intelligence:</span> Harnessing the power of AI, we create intelligent systems that automate processes, analyze data, and generate valuable insights. From machine learning algorithms to natural language processing, our AI solutions drive efficiency, enhance decision-making, and unlock new opportunities for growth.</li>
                <li>3. <span className='font-bold'>Cloud Services: </span>  We offer cloud-based solutions that enable businesses to leverage the scalability, flexibility, and security of the cloud. Our expertise in cloud architecture, migration, and management ensures a seamless transition to the cloud environment, empowering organizations to optimize their operations and reduce costs.</li>
                {/*<li>4. <span className='font-bold'>Internet of Things (IoT):</span> As the IoT continues to revolutionize industries, we help businesses capitalize on its potential. Our IoT solutions connect devices, collect real-time data, and enable remote control and monitoring. By integrating IoT technologies into existing systems or developing new IoT-enabled products, we drive innovation and enable smarter, more efficient workflows.</li>*/}
                <li>4. <span className='font-bold'>Cybersecurity: </span>  In an increasingly connected world, protecting digital assets and ensuring data privacy is paramount. Our cybersecurity experts employ robust strategies to safeguard networks, applications, and sensitive information from threats. We conduct thorough assessments, implement robust security measures, and provide ongoing monitoring to ensure your digital infrastructure remains secure.</li>
                <li>5. <span className='font-bold'>Digital Marketing: </span> In today's digitally-driven landscape, establishing a strong online presence is crucial for business success. Our digital marketing specialists harness the power of data-driven strategies to optimize your brand's visibility, engage target audiences, and drive meaningful conversions. From crafting compelling content to implementing SEO techniques, we leverage the latest tools and trends to ensure your digital marketing efforts yield maximum impact.</li>
                <li>6. <span className='font-bold'>Ad Concepts: </span> Capture attention and stand out in the digital landscape with our innovative ad concepts. In today's interconnected world, where every click matters, our team specializes in crafting compelling advertisements that not only grab your audience's attention but also leave a lasting impact. We blend creativity with strategic insights to develop ad campaigns that resonate with your target audience, driving engagement and fostering brand loyalty.</li>
                <li>7. <span className='font-bold'>UI/UX Design: </span> Immerse your audience in an unparalleled digital experience with our cutting-edge UI/UX design services. Our design experts craft interfaces that not only captivate but also enhance user engagement. From intuitive navigation to aesthetically pleasing visuals, we prioritize user experience to elevate your digital presence. Partner with us to redefine your brand's digital interface and leave a lasting impression on your audience.</li>
                <li>8. <span className='font-bold'>Graphics Design: </span> Transform your brand's visual identity with our dynamic graphics design services. In today's interconnected world, where visual appeal is crucial, our creative team specializes in crafting eye-catching and impactful designs. From striking logos to engaging marketing collateral, we blend artistic flair with strategic thinking to enhance your brand's aesthetic presence. Elevate your digital presence through compelling and visually stunning graphics tailored to your brand's unique identity.</li>
                <li>9. <span className='font-bold'>Blockchain Technology: </span> Revolutionize your digital landscape with our expertise in blockchain technology. In today's world, where security is paramount, our team of blockchain specialists goes beyond safeguarding digital assets. We harness the power of blockchain to create transparent, secure, and decentralized solutions that redefine data privacy and integrity. From comprehensive assessments to implementing cutting-edge security measures, we ensure your digital infrastructure remains not only protected but also at the forefront of technological innovation. Embrace the future of secure and transparent transactions with our blockchain technology solutions.</li>
            </ol>
          </div>
          <div className='mt-4'>
            <h2 className='font-bold'>Our Approach:</h2>
            <p className='mt-3'>At SkyFalke, we prioritize a client-centric approach. We believe in understanding your unique challenges and goals to deliver tailored solutions that exceed expectations. We collaborate closely with our clients throughout the development process, fostering transparency, communication, and trust.</p>
          </div>
          <div className='mt-4'>
            <h2 className='font-bold'>Why choose us:</h2>
            <ol>
                <li>1. <span className='font-bold'>Expert Team:</span> Our team consists of skilled professionals with extensive experience across various domains. We are dedicated to staying ahead of the curve, continuously learning and adopting the latest technologies to deliver cutting-edge solutions.</li>
                <li>2. <span className='font-bold'>Innovation:</span> We thrive on innovation and strive to bring fresh ideas to the table. We embrace new technologies and methodologies to develop solutions that push boundaries and drive digital transformation.</li>
                <li>3. <span className='font-bold'>Quality Focus: </span>  We are committed to delivering high-quality products and services. Our rigorous testing processes ensure that our solutions are reliable, secure, and user-friendly, providing an exceptional experience for our clients and end-users.</li>
                <li>4. <span className='font-bold'>Client Satisfaction:</span> Our success is measured by the satisfaction of our clients. We work collaboratively, maintaining open lines of communication, and providing responsive support to ensure that our clients' needs are met and their expectations are exceeded.</li>
            </ol>
          </div>
          <p className='mt-4'>
            We look forward to partnering with you on your technological journey. Contact us today to explore how SkyFalke can help you embrace the possibilities of the digital world.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
