import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 w-full my-32">
      <h1 className="text-4xl font-bold text-gray-800 mb-6">404 - Page Not Found</h1>
      <p className="text-gray-600 mb-4">Oops! The page you are looking for does not exist.</p>
      <Link
        to="/"
        className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none"
      >
        Go to Homepage
      </Link>
    </div>
  );
};

export default NotFoundPage;
