import React from 'react';

const PricingPage = () => {
  const plans = [
    {
      title: 'Basic Plan',
      price: 'Free',
      features: ['Feature 1', 'Feature 2', 'Feature 3'],
    },
    {
      title: 'Pro Plan',
      price: '$99/month',
      features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4'],
    },
    {
      title: 'Enterprise Plan',
      price: 'Contact Us',
      features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4', 'Feature 5'],
    },
  ];

  return (
    <div className="container mx-auto w-full my-32">
      <h1 className="text-4xl font-bold text-center mt-10 mb-12">Choose the Right Plan for You</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {plans.map((plan, index) => (
          <div key={index} className="bg-white rounded-lg shadow-lg p-8 flex flex-col justify-between">
            <div>
              <h2 className="text-2xl font-semibold mb-4">{plan.title}</h2>
              <div className="flex items-center mb-6">
                <span className="text-3xl font-bold">{plan.price}</span>
                {plan.price !== 'Free' && <span className="ml-2 text-gray-600">/month</span>}
              </div>
              <ul className="mb-8 space-y-2">
                {plan.features.map((feature, index) => (
                  <li key={index} className="flex items-center">
                    <svg className="w-5 h-5 mr-2 text-gray-600" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fillRule="evenodd"
                        d="M15.293 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L7 11.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
            <button className="block w-full py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none">
              Choose Plan
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingPage;
