import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';
import avatarUrl from '../assets/google-logo.png';
import logo from '../assets/logo2.png';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    window.localStorage.clear();
  };

  const handleClick = () => setNav(!nav);

  const handleClose = () => setNav(!nav);

  useEffect(() => {
    if (window.localStorage.getItem("loggedIn")) {
      setIsSignedIn(true);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <motion.div
      className={`navbar w-full ${scrolling ? 'bg-white text-slate-900' : 'bg-slate-900 text-white'}`}
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ type: 'spring', duration: 0.8 }}
    >
      <div className='px-4 flex justify-between items-center w-full h-full'>
        <Link to="/">
          <img src={logo} alt='logo' className="md:h-10 h-8" />
        </Link>
        <div className='flex items-center'>
          <ul className='hidden md:flex md:space-x-0'>
            <li>
              <Link to='/' className='menu-link roboto-medium'>
                Home
              </Link>
            </li>
            <li>
              <Link to='about-us' className='menu-link roboto-medium'>
                About Us
              </Link>
            </li>
            {/* <li>
              <Link to="products" className='menu-link roboto-medium'>
                Products
              </Link>
            </li> */}
            <li>
              <Link to="web-hosting" className='menu-link roboto-medium'>
                Web Hosting
              </Link>
            </li>
            <li>
              <Link to="3D-printing" className='menu-link roboto-medium'>
                3D Printing
              </Link>
            </li>
            <li>
              <Link to="shop" className='menu-link roboto-medium'>
                Shop
              </Link>
            </li>
          </ul>
        </div>
        <div className='hidden md:flex pr-4 md:mr-3'>
          {isSignedIn ? (
            <>
              <p className='font-bold'>Hello, Ronald</p>
              <button
                onClick={toggleDropdown}
                className="relative focus:outline-none"
              >
                <img src={avatarUrl} alt="User Avatar" className="w-10 h-10 rounded-full" />
                {isDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 shadow-lg rounded-md z-10">
                    <ul className="py-2">
                      <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                        <button className='text-blue-500 bg-transparent border-none hover:text-blue-700' onClick={handleLogout}>
                          Sign Out
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </button>
            </>
          ) : (
            <>
              <Link to='signin'>
                <button className='text-sm flex items-center justify-center text-white shadow-md px-8 py-3 border-none bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 hover:text-white roboto-medium rounded-full'>
                  Sign In
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 2a4 4 0 0 0-4 4v2H5a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2h-1V6a4 4 0 0 0-4-4zM8 6a2 2 0 1 1 4 0v2H8V6z" clipRule="evenodd" />
                  </svg>
                </button>
              </Link>
            </>
          )}
        </div>
        <div className='md:hidden mr-4' onClick={handleClick}>
          {!nav ? (
            <MenuIcon className='w-5' />
          ) : (
            <XIcon className='w-5' />
          )}
        </div>
      </div>
      <ul className={!nav ? 'hidden' : 'absolute bg-slate-900 w-full px-8'}>
        <li className='border-b-2 border-white w-full text-xl'>
          <Link onClick={handleClose} to="/" className="text-white roboto-medium">
            Home
          </Link>
        </li>
        <li className='border-b-2 border-white w-full text-xl'>
          <Link onClick={handleClose} to="about" className="text-white roboto-medium">
            About Us
          </Link>
        </li>
        <li className='border-b-2 border-white w-full text-xl'>
          <Link onClick={handleClose} to="web-hosting" className="text-white roboto-medium">
             Web Hosting
          </Link>
        </li>
        <li className='border-b-2 border-white w-full text-xl'>
          <Link onClick={handleClose} to="3D-printing" className="text-white roboto-medium">
            3D Printing
          </Link>
        </li>
        <li className='border-b-2 border-white w-full text-xl'>
          <Link onClick={handleClose} to="shop" className="text-white roboto-medium">
            Shop
          </Link>
        </li>
        <div className='flex flex-col my-4'>
          {isSignedIn ? (
            <div className='flex items-center'>
              <p className='text-white text-lg mr-2'>Hello, Ronald</p>
              <Link to='/user-profile'>
                <img src={avatarUrl} alt='User Avatar' className='w-10 h-10 rounded-full' />
              </Link>
            </div>
          ) : (
            <>
              <Link to='signin'>
                <button className='w-full px-8 py-3 border-none bg-transparent text-white mr-4 hover:text-blue-500 hover:bg-transparent roboto-medium'>
                  Sign In
                </button>
              </Link>
              <Link to='signup'>
                <button className='w-full text-sm flex items-center justify-center text-white shadow-md px-8 py-3 border-none bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 hover:text-white roboto-medium rounded-md'>
                    Sign Up
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 2a4 4 0 0 0-4 4v2H5a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2h-1V6a4 4 0 0 0-4-4zM8 6a2 2 0 1 1 4 0v2H8V6z" clipRule="evenodd" />
                    </svg>
                  </button>
              </Link>
            </>
          )}
        </div>
      </ul>
    </motion.div>
  );
};

export default Navbar;
