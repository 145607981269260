import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { baseURL } from '../../utils/constant';
import logo from '../../assets/logo2.png';
import { FcGoogle } from "react-icons/fc";
import { GoogleLogin } from 'react-google-login'
import { FaSpinner } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';

const GoogleSignInButton = ({ onSuccess, onFailure }) => {
  return (
    <GoogleLogin
      clientId="1051543273039-aac5fchqbptdjft8lli4ut1l29hj9rhq.apps.googleusercontent.com"
      buttonText="Sign In with Google"
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy={'single_host_origin'}
      isSignedIn={true}
      render={renderProps => (
        <div onClick={renderProps.onClick} className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-blue-100 hover:cursor-pointer">
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-navy-700 dark:text-white">Sign In with Google</h5>
        </div>
      )}
    />
  );
};


const SignInPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [loginFail, setLoginFail] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleGoogleSignIn = (googleData) => {    
    const profile = googleData.profileObj; // User profile data
    const idToken = googleData.tokenId;
    const tokenId = idToken;
    console.log('Google User Profile:', profile);
  console.log('Google ID Token:', idToken);
    axios
      .post(`${baseURL}/google-login`, { token: tokenId })
      .then((response) => {
        if (response.data.status === 'ok') {
          window.localStorage.setItem('token', response.data.data);
          window.localStorage.setItem('loggedIn', 'true');
          setTimeout(() => {
            setLoading(false);
          }, 2000);
          window.location.href = '/';
        } else {
          setLoginFail(response.data.error);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error('Google Sign-In failed:', error);
        setLoading(false);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    //Validations
    const validationErrors = {};
    if (!email) {
        validationErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
        validationErrors.email = 'Invalid email address';
    }
    if (!password) {
        validationErrors.password = 'Password is required';
    } else if (password.length < 6) {
        validationErrors.password = 'Password must be at least 6 characters long';
    }
    if (Object.keys(validationErrors).length === 0) {
        const userData = {
            email,
            password
        }
        try {
            await axios.post(`${baseURL}/adminLogin`, userData)
                .then((response) => {
                      if (response.data.status === "ok") {                        
                        window.localStorage.setItem("token", response.data.data);
                        window.localStorage.setItem("adminLoggedIn", "true"); 
                        toast.success('Login successful!', {
                          position: toast.POSITION.TOP_CENTER
                        });
                        setTimeout(() => {
                            setLoading(false);
                        }, 2000);                        
                        window.location.href = './admin';
                    } else if (response.data.status === "notFound") {                        
                      toast.error("Account with that email was not found", {
                        position: toast.POSITION.TOP_CENTER
                      });
                      setTimeout(() => {
                          setLoading(false);
                      }, 2000); 
                  } else if (response.data.status === "invalidPassword") {                        
                    toast.error("Invalid Password", {
                      position: toast.POSITION.TOP_CENTER
                    });
                    setTimeout(() => {
                        setLoading(false);
                    }, 2000);
                } else {
                      toast.error(`${response.data.error}`, {
                        position: toast.POSITION.TOP_CENTER
                      });
                        setLoading(false);
                    }
                });
            setErrors({});
        }
        catch (e) {
            if (e.code === "ERR_NETWORK") {
                setLoginFail('It seems you not connected to Internet.');
            }
            setLoading(false);
        }
    } else {
        // Form is invalid, update errors state
        setErrors(validationErrors);
        setLoading(false);
    }
}

  return (
    <>
    <ToastContainer />
    <div className="min-h-screen bg-gray-100">
      <nav className="w-full flex items-center justify-between px-4 py-5 bg-transparent top-0 fixed">
        <div>
            <Link to='/'><img src={logo} alt="Logo" className="h-8" /></Link>
        </div>
      </nav>
      <div className="flex items-center justify-center min-h-screen mx-auto px-2">
        <div className="max-w-md w-full p-6 bg-white rounded-md shadow-lg">
          <h2 className="mb-2.5 text-xl md:text-3xl font-bold text-navy-700 dark:text-white">Sign In</h2>
          <p className="mb-9 ml-1 text-sm md:text-base text-gray-400">Enter your email and password to sign in!</p>
          <div onClick={handleGoogleSignIn}>
            <GoogleSignInButton onSuccess={handleGoogleSignIn} onFailure={(error) => console.log('Google Sign-In failed:', error)} />
          </div>
        <div className="mb-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          <p className="text-base text-gray-600 dark:text-white"> or </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div>        
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block mb-2 font-semibold text-navy-700 text-navy-700 " htmlFor="email">Email*</label>
              <input
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                type="email"
                id="email"
                name="email"
                value={email}
                placeholder="mail@example.com"
                onChange={handleEmailChange}
                required
                autoFocus
              />
              {errors.email && <span className="text-red-500">{errors.email}</span>}
            </div>
            <div className="mb-6">
              <label className="block mb-2 font-semibold" htmlFor="password">Password*</label>
              <input
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Min. 8 characters"
                required
              />
              {errors.password && <span className="text-red-500">{errors.password}</span>}
            </div>
            {/* <div className="mt-4 text-right">
              <Link to="/forgot-password" className="text-blue-500 hover:text-blue-700">Forgot Password?</Link>
            </div> */}
            <div className="flex justify-center">
              <button
                className="linear mt-2 w-full rounded-xl bg-blue-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-blue-600 hover:text-white active:bg-blue-700"
                type="submit"
                disabled={loading} 
              >
                 {loading && (
                        <FaSpinner className='animate-spin mx-auto' />
                    )}
                    {!loading && 'Sign In'}
              </button>
            </div>
          </form>
          {loginFail && <div className="mt-2 text-center text-red-500">Something happened, try again</div>}        
       
          <div className="mt-4">
            <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
              Not registered yet?
            </span>
            <Link to="./signup" className="ml-1 text-sm font-medium text-blue-500 hover:text-blue-600 dark:text-white">Sign Up</Link>
          </div>          
        </div>
      </div>
    </div>
    </>
  );
};

export default SignInPage;
