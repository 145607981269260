import React from 'react'

const Claims = () => {
  return (
    <div name='platforms' className='w-full my-32'>
    <div className='max-w-[1240px] mx-auto px-2 mt-10 text-xl text-left'>
      <div className='mt-4'>
          <h2 className='font-bold'>Prompt and Efficient Claims Handling</h2>
          <p className='mt-3'>At SkyFalke, we understand that accidents and incidents can happen. Our dedicated claims team is committed to providing prompt and efficient claims handling services. We strive to make the claims process as smooth and hassle-free as possible for our clients.</p>
      </div>
      <div className='mt-4'>
          <h2 className='font-bold'>Personalized Assistance</h2>
          <p className='mt-3'>We believe in providing personalized assistance to our clients throughout the claims process. Our claims professionals work closely with you, understanding your unique circumstances and requirements. They guide you step-by-step, ensuring that all necessary documentation is provided and assisting you with any queries or concerns you may have.</p>
      </div>
      <div className='mt-4'>
          <h2 className='font-bold'>Timely Communication</h2>
          <p className='mt-3'>We value effective communication and understand the importance of keeping our clients informed about their claims. Our team maintains regular contact, providing updates on the progress of the claim and addressing any questions or additional information required.</p>
      </div>
      <div className='mt-4'>
          <h2 className='font-bold'>Transparency and Fairness</h2>
          <p>We prioritize transparency and fairness in our claims handling. Our team conducts thorough investigations, objectively assessing claims based on established criteria and industry standards. We strive to achieve fair and equitable outcomes for all parties involved.</p>
      </div>
      <div className='mt-4'>
          <h2 className='font-bold'>Commitment to Customer Satisfaction</h2>
          <p>At SkyFalke Technologies, customer satisfaction is our utmost priority. We continuously evaluate and enhance our claims services to meet the evolving needs of our clients. We welcome feedback and take it into consideration to further improve our claims handling processes.</p>
      </div>
      <div className='mt-4'>
          <h2 className='font-bold'>Collaborative Approach</h2>
          <p>We believe in fostering a collaborative approach with our clients, insurers, and other stakeholders involved in the claims process. By working together, we aim to achieve optimal outcomes, minimize disruptions, and expedite the resolution of claims.</p>
      </div>
      <div className='mt-4'>
          <h2 className='font-bold'>Covered Incidents</h2>
          <p>The following are incidents SkyFalke handles:</p>
          <ol>
            <li>A. Property damage</li>
            <li>B. Equipment malfunctionn</li>
            <li>C. Data breaches</li>
            <li>D. Business interruption</li>
            <li>E. Liability claims</li>
          </ol>
      </div>
      <div className='mt-4'>
          <h2 className='font-bold text-3xl'>Claims FAQs and Resources</h2>
            <h3 className='font-bold text-2xl'>Frequently Asked Questions (FAQs)</h3>
            <div className='mt-3'>
                <h3 className='font-bold'>Q: How do I initiate a claim?</h3>
                <p>A: To initiate a claim, please contact our dedicated claims team at [email protected] or call our claims hotline at [phone number]. They will guide you through the process and provide you with the necessary forms and documentation requirements.</p>
            </div>
            <div className='mt-3'>
                <h3 className='font-bold'>Q: What information do I need to provide when filing a claim?</h3>
                <p>A: When filing a claim, please ensure you have the following information ready:</p>
                <ol>
                    <li>Date and details of the incident</li>
                    <li>Description of damages or losses incurred</li>
                    <li>Supporting documentation such as photographs, invoices, or repair estimates</li>
                </ol>
            </div>
            <div className='mt-3'>
                <h3 className='font-bold'>Q: What is the expected timeline for claim processing?</h3>
                <p>A: The timeline for claim processing can vary depending on the complexity of the claim and the availability of required documentation. Our claims team is dedicated to handling claims promptly, and they will keep you informed about the progress of your claim throughout the process.</p>
            </div>
            <div className='mt-3'>
                <h3 className='font-bold'>Q: How can I track the status of my claim?</h3>
                <p>A: You can track the status of your claim by contacting our claims team directly. They will provide regular updates on the progress of your claim and address any queries or concerns you may have.</p>
            </div>
            <div className='mt-3'>
                <h3 className='font-bold'>Q: What if I have additional questions or need further assistance?</h3>
                <p>A: If you have any additional questions or require further assistance regarding your claim, please feel free to reach out to our claims team at <span className='italic'>legals@skyfalke.com</span> or call our claims hotline at <span className='italic'>0717797238</span>. They will be happy to assist you.</p>
            </div>
            <div className='mt-3'>
                <h3 className='font-bold'>Claims Resources</h3>
                <ol>
                    <li><span className='font-bold'>Claims Forms:</span> Download and access the necessary claims forms directly from our website. Fill out the required information and submit them to our claims team for processing.</li>
                    <li><span className='font-bold'>Claims Contact Information: </span> Find the contact details of our dedicated claims team, including email and phone numbers, for quick and direct communication.</li>
                    <li><span className='font-bold'>Claims Documentation Guidelines: </span>  Understand the documentation requirements for different types of claims. This resource will help you gather and submit the necessary documents to support your claim.</li>
                    <li><span className='font-bold'>Claims Process Overview: </span> Get an overview of our claims process, from filing a claim to the resolution stage. This guide will help you understand the steps involved and what to expect throughout the process.</li>
                    <li><span className='font-bold'>Claims FAQs: </span> Explore our comprehensive list of frequently asked questions related to claims. This resource addresses common queries and provides clarity on various aspects of the claims process</li>
                </ol>
            </div>
      </div>
  </div>
  </div>
  )
}

export default Claims