import React from 'react';

const CommercePage = () => {
  const benefits = [
    {
      title: 'Wide Range of Products',
      description: 'Discover a vast selection of high-quality technology products for your business needs.',
    },
    {
      title: 'Secure and Convenient Shopping',
      description: 'Shop with confidence knowing that your transactions are secure, and enjoy a seamless online shopping experience.',
    },
    {
      title: 'Competitive Pricing',
      description: 'Get the best value for your money with our competitive pricing and special discounts for bulk orders.',
    },
    {
      title: 'Fast and Reliable Shipping',
      description: 'Experience swift and reliable shipping services to ensure your products are delivered on time.',
    },
    {
      title: 'Responsive Customer Support',
      description: 'Our dedicated customer support team is ready to assist you with any inquiries or concerns you may have.',
    },
    // Add more benefits as needed
  ];

  return (
    <div className="container mx-auto my-32 w-full text-lg">
      <h1 className="text-4xl font-bold text-center mt-10 mb-12">Welcome to SkyFalke Commerce</h1>
      <p className="text-center text-gray-700 mb-12">
        SkyFalke Commerce is your trusted destination for all your technology needs. We offer a wide range of cutting-edge products
        at competitive prices, ensuring that you can find the perfect solutions for your business. With our secure and convenient
        online shopping platform, fast shipping, and responsive customer support, we aim to provide an exceptional experience for
        our valued customers. Our platform will be launched soon.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {benefits.map((benefit, index) => (
          <div key={index} className="bg-white rounded-lg shadow-lg p-6">
            <h2 className="text-xl font-semibold mb-2">{benefit.title}</h2>
            <p className="text-gray-700">{benefit.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommercePage;
