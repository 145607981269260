import React, { useState } from 'react';

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const faqs = [
    {
      question: 'Tell me about SkyFalke?',
      answer: 'SkyFalke is a technology company dedicated to providing digital and technology solutions to all industries, Our main focus is identifying, developing and creating cutting edge technology solutions to problems arising in this fast paced environment. We are also specialized in graphics & digital design, business intelligence and cloud solutions.',
    },
    {
      question: "Why should we choose you?",
      answer: `Other than the skills and the experience, you should choose us for
      three main reasons:      
          1. We put our 100% on every project that We work on, We make sure that the outputs
              that We provide are not half baked and if possible We can deliver on or before the
              deadline with highest quality output.
          2. We are proactive and We love sharing ideas to help business grow .
          3. We are result-oriented and We will provide you with relevant software applications
          that will help your decision making  and help you decide what's best for your business.
          
      `,
    },
    {
      question: "How are you going to react about a negative feedback from a client?",
      answer: `We strive to improve our services & products continually, so feedback like this is valuable.`,
     },
     {
      question: "If We gave you a bunch of tasks but you realized you can't meet the deadline. What would you do?",
      answer:`We will prioritize the tasks with the highest value or impact for your business and then ask
      you for additional hours or days to finish the remaining tasks because honestly there are times that we think we can finish a task on a certain time frame but due to unexpected challenges there might be some 
      delays`,
    },
    {
      question: 'What do you do?',
      answer: 'We help businesses and industries grow by harnessing the power of technology in their operations, we have a talented and dedicated team of experts that drive our company`s goals and success.',
    },
    {
      question: "What type of projects do you usually work on?",
      answer: `We usually work on projects related to business, institution and company software solutions, blockchain technology, AI solutions, creative graphics design and cloud technologies etc.
      This can include ERP & CRM systems, management systems, embedded systems, AI integration, SASS products, company custome softwares, cloud migration & integration, digital marketing, UI/UX design, Ad concepts, cinematography, creative illustrations and software consultation.
      `,
     },
    {
      question: 'Do you have any product(s) solutions?',
      answer: 'We have a wide range of products solutions still under in-house development, check our explore page to learn more about some of our latest products in the market.',
    },
    {
      question: "What is the best way to message you?",
      answer: `The best way to message us is through email. You can also contact us
      via social media or by phone. You can find our contact information on this website.
      `,
    },
  ];

  return (
    <div className="w-full mx-auto">
      {faqs.map((faq, index) => (
        <div key={index} className="mb-4">
          <button
            className="w-full flex justify-between items-center py-4 px-6 bg-slate-900 hover:bg-slate-700  rounded-md focus:outline-none"
            onClick={() => toggleAccordion(index)}
          >
            <span className="font-semibold">{faq.question}</span>
            <svg
              className={`w-5 h-5 transition-transform duration-300 ${
                activeIndex === index ? 'transform rotate-180' : ''
              }`}
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M6.293 8.293a1 1 0 0 1 1.414 0L10 10.586l2.293-2.293a1 1 0 1 1 1.414 1.414l-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 0-1.414z"
              />
            </svg>
          </button>
          {activeIndex === index && (
            <div className="py-2 px-6 bg-gray-100 rounded-b-md">
              <p className="text-gray-800">{faq.answer}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQSection;
