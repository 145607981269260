import React, { useState, useEffect } from 'react';
import { PropagateLoader } from 'react-spinners';
import { css } from '@emotion/react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from "axios";
import { baseURL } from "../utils/constant";
import { FaSpinner } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';


const PartnersPage = () => {
  const [partnerType, setPartnerType] = useState('');
  const [clientLogo, setClientLogo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [spinning, setSpining] = useState(false);
  const [errors, setErrors] = useState({});
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handlePartnerTypeChange = (event) => {
    setPartnerType(event.target.value);
  };

  const solutions = [
    'Cloud Computing',
    'Data Analytics',
    'Artificial Intelligence',
    'Internet of Things',
    'Cybersecurity',
  ];

  const handleSubmit = async(e) => {
    e.preventDefault();
    setSpining(true);

    const validationErrors = {};
    if (!email) {
        validationErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
        validationErrors.email = 'Invalid email address';
    }
    if (!name) {
        validationErrors.name = 'Name is required';
    } 
    if (!message) {
      validationErrors.message = 'Please enter your message';
  } 
    if (!partnerType) {
    validationErrors.type = 'Please choose partner type';
  } 
  if (!phoneNumber) {
    validationErrors.phoneNumber = 'Please enter your phone number';
  } 
    if (Object.keys(validationErrors).length === 0) {
        // Form is valid, submit data to the server
        const payload = {
            email,
            name,
            message,
            phoneNumber,
            partnerType
        };
        try {
            await axios.post(`${baseURL}/request-partnership`, payload)
                .then((response) => {
                    if (response.data.status === "ok") {
                      toast.success('We received your request! please be patient as we review it, one of our staff will contact you.', {
                        position: toast.POSITION.TOP_CENTER
                      });
                      setSpining(false);
                      setPartnerType('');
                      setName('');
                      setEmail('');
                      setMessage('');
                      setPhoneNumber('');                     
                    } else {
                      toast.error('Failed sending request. Please try again.', {
                        position: toast.POSITION.TOP_CENTER
                      });
                    }
                });
            setErrors({});
        }
        catch (e) {
            if (e.code === "ERR_NETWORK") {
              toast.error('It seems you not connected to Internet.', {
                position: toast.POSITION.TOP_CENTER
              });
            }
            setSpining(false); 
        }
    } else {
        setErrors(validationErrors);
        setSpining(false); 
    }
  }  

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '20vh', 
  };

  useEffect(() => {
    window.slick = require('slick-carousel');
    async function getClients() {
      try {
        const clients = await axios.get(`${baseURL}/getClients`);
        setClientLogo(clients.data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    }

    getClients();
  }, []);
  

  return (
    <>
     <ToastContainer />
    <div className="container mx-auto w-full my-32 text-xl">
      <h1 className="text-4xl font-bold text-center mt-10 mb-12">Our Partners</h1>
      {loading ? (
        <div style={containerStyle}>
          <PropagateLoader css={override} color="#36D7B7" loading={loading} />
        </div>
        ) :
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-8">
        { clientLogo && clientLogo.map((partner, index) => (
          <div key={index} className="flex items-center justify-center">
            <img src={require(`../webimages/${partner.logo}`)} alt="Partner Logo" className="h-16 sm:h-20 md:h-24" />
          </div>
        ))}
      </div>
      }    
      <div className="mt-16 text-center">
        <h2 className="text-2xl font-semibold mb-4">Want To Partner with Us?</h2>
        <p className="text-gray-700">
          At SkyFalke, we hold collaboration and partnerships in high regard. We believe in the power of coming together to create impactful solutions. If you share our vision and are eager to forge a meaningful partnership, we invite you to take the first step. By expressing your interest in becoming a partner with SkyFalke, you open the door to a world of possibilities.
          <br /><br />
         We are committed to building lasting relationships that drive innovation and growth. As a potential partner, you'll gain access to a network of like-minded professionals and industry experts. This is your chance to embark on a journey where your ideas are nurtured, your expertise is valued, and your goals align with ours.
          <br /><br />
          To embark on this exciting path, please take a moment to fill out the form below. Once we receive your information, we will eagerly reach out to initiate the conversation. We believe that together, we can make a lasting impact on the technology landscape.
        </p>
        <form className="max-w-md mx-auto mt-8 bg-white rounded-lg shadow-lg p-8 border" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block mb-2 font-semibold text-gray-700" htmlFor="name">Your Company/Name</label>
              <input className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" type="text" value={name} onChange={(e)=>setName(e.target.value)}  required />
              {errors.name && <span className="text-red-500">{errors.name}</span>}
            </div>
            <div className="mb-4">
              <label className="block mb-2 font-semibold text-gray-700" htmlFor="name">Phone Number</label>
              <input className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" type="telephone" value={phoneNumber} onChange={(e)=>setPhoneNumber(e.target.value)}  required />
              {errors.phoneNumber && <span className="text-red-500">{errors.phoneNumber}</span>}
            </div>
            <div className="mb-4">
              <label className="block mb-2 font-semibold text-gray-700" htmlFor="email">Your Comapny Email</label>
              <input className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" type="email" value={email} onChange={(e)=>setEmail(e.target.value)} required />
              {errors.email && <span className="text-red-500">{errors.email}</span>}
            </div>
            <div className="mb-4">
              <label className="block mb-2 font-semibold text-gray-700" htmlFor="partnerType">Solution/Service</label>
              <select className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" id="partnerType" name="partnerType" value={partnerType} onChange={handlePartnerTypeChange} required>
                <option value="">Select a Solution/Product</option>
                {solutions.map((type) => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
              {errors.type && <span className="text-red-500">{errors.type}</span>}
            </div>
            <div className="mb-4">
              <label className="block mb-2 font-semibold text-gray-700" htmlFor="message">Additional Infomation</label>
              <textarea className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" value={message} onChange={(e)=>setMessage(e.target.value)} rows="4" required></textarea>
              {errors.message && <span className="text-red-500">{errors.message}</span>}
            </div>
            <button className="w-full py-2 bg-yellow-500 text-white rounded-md focus:outline-none transition shadow-md" type="submit" disabled={spinning}>
            {spinning && (
                  <FaSpinner className='animate-spin mx-auto' />
              )}
              {!spinning && 'Submit'}
            </button>
          </form>
      </div>
      {/* <div className="mt-16">
        <h2 className="text-2xl font-semibold text-center mb-4">Solutions That You Can Partner With Us</h2>
        <div className="flex flex-wrap justify-center">
          {solutions.map((solution, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg mx-4 my-2 p-4">
              <h3 className="text-lg font-semibold mb-2">{solution}</h3>
              <p className="text-gray-700">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae turpis sed leo venenatis finibus nec a sem.
              </p>
            </div>
          ))}
        </div>
      </div> */}
    </div>
    </>
  );
};

export default PartnersPage;
