import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { baseURL } from '../utils/constant';
import logo from '../assets/logo2.png';
import { FaSpinner } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import { useContext } from "react";
import { RecoveryContext } from "./Auth";

const ResetPassword = () => {
  const { setPage, email } = useContext(RecoveryContext);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);  

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPassChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    //Validations
    const validationErrors = {};
    if (!password) {
        validationErrors.password = 'Password is required';
    } else if (password.length < 6) {
        validationErrors.password = 'Password must be at least 6 characters long';
    }
    if (!confirmPassword) {
        validationErrors.confirmPassword = 'Please confirm your password';
    } else if (password !== confirmPassword) {
        validationErrors.confirmPassword = 'Passwords do not match';
    }

    if (Object.keys(validationErrors).length === 0) {
        const userData = {
            confirmPassword,
            email
        }
        try {
            await axios.put(`${baseURL}/updatePassword`, userData)
                .then((response) => {
                    if (response.data.status === "ok") {                       
                        setTimeout(() => {
                            setLoading(false);
                        }, 2000);
                        toast.success('Password changed successfully!', {
                          position: toast.POSITION.TOP_CENTER
                        });
                        setPage("recovered");
                    } else {
                      toast.error(`${response.data.status}`, {
                        position: toast.POSITION.TOP_CENTER
                      });
                        setLoading(false);
                    }
                });
            setErrors({});
        }
        catch (e) {
            if (e.code === "ERR_NETWORK") {
              toast.error('It seems you not connected to Internet.', {
                position: toast.POSITION.TOP_CENTER
              });
            }
            setLoading(false);
        }
    } else {

        setErrors(validationErrors);
        setLoading(false);
    }
}

  return (
    <>
    <ToastContainer />
    <div className="min-h-screen bg-gray-100">
      <nav className="w-full flex items-center justify-between px-4 py-5 bg-transparent top-0 fixed">
        <div>
            <Link to='/'><img src={logo} alt="Logo" className="h-8" /></Link>
        </div>
      </nav>
      <div className="flex items-center justify-center min-h-screen mx-auto px-2">
        <div className="max-w-md w-full p-6 bg-white rounded-md shadow-lg">
          <h2 className="mb-2.5 text-xl md:text-3xl font-bold text-navy-700 dark:text-white text-center">Reset Password</h2>
      
          <form onSubmit={handleSubmit}>
            <div className="mb-3 md:mb-4">
              <label className="block mb-2 font-semibold text-navy-700 text-navy-700 " htmlFor="email">New Password*</label>
              <input
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                type="password"
                id="password"
                name="password"
                value={password}
                placeholder="*******"
                onChange={handlePasswordChange}
                required
                autoFocus
              />
              {errors.password && <span className="text-red-500">{errors.password}</span>}
            </div>   

            <div className="mb-3 md:mb-4">
              <label className="block mb-2 font-semibold text-navy-700 text-navy-700 " htmlFor="email">Confirm Password*</label>
              <input
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                type="password"
                id="confirm password"
                name="confirm password"
                value={confirmPassword}
                placeholder="*******"
                onChange={handleConfirmPassChange}
                required
                autoFocus
              />
              {errors.confirmPassword && <span className="text-red-500">{errors.confirmPassword}</span>}
            </div>     
            <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="newsletter"
                    aria-describedby="newsletter"
                    type="checkbox"
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                    required=""
                  ></input>
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="newsletter"
                    className="font-light text-gray-500 dark:text-gray-300"
                  >
                    I accept the{" "}
                    <a
                      className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                      href="/terms"
                    >
                      Terms and Conditions
                    </a>
                  </label>
                </div>
              </div>
            <div className="flex justify-center">
              <button
                className="linear mt-2 w-full rounded-xl bg-blue-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-blue-600 hover:text-white active:bg-blue-700"
                type="submit"
                disabled={loading} 
              >
                 {loading && (
                        <FaSpinner className='animate-spin mx-auto' />
                    )}
                    {!loading && 'Reset Password'}
              </button>
            </div>
          </form>         
        </div>
      </div>
    </div>
    </>
  );
};

export default ResetPassword;
