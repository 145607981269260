import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import {baseURL} from "../utils/constant";
import { PropagateLoader } from 'react-spinners';
import { css } from '@emotion/react';


const Explore = () => {
  const [solution, setSolution] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getSolutions() {
      try {
        await axios.post(`${baseURL}/fetchSolutions`)
          .then((response) => {
            setSolution(response.data.data);
            setLoading(false);
          })
          .catch((error) => {
            console.error('Error fetching product solutions:', error);
          });
      } catch (error) {
        console.error('Something went wrong:', error);
      }
    }
    getSolutions();
  }, []);

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '50vh', 
  };

  const override = css`
    display: block;
    margin: 0 auto;
  `;

  return (
    <div className="container my-32 w-full mx-auto">
      <h1 className="text-4xl font-bold text-center mt-10 mb-12">Explore Our Solutions</h1>
      {loading ? (
        <div style={containerStyle}>
          <PropagateLoader css={override} color="#36D7B7" loading={loading} />
        </div>
        ) :
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
        { solution && solution.map((domain, index) => (
          <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden">
            <img src={domain.image} alt={domain.title} className="w-full h-64 object-cover" />
            <div className="p-6">
              <h2 className="text-xl font-semibold mb-3">{domain.title}</h2>
              <p className="text-gray-700">Discover how {domain.title} technology solution empowers industries.</p>
              <Link to={`../learn-more/${domain._id}`}>
                <button className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none">
                  Learn More
                </button>
              </Link>
            </div>
          </div>
        ))}
      </div>
      }
    </div>
  );
};

export default Explore;
