import React from 'react';

const GuidesPage = () => {
  const guides = [
    {
      title: 'Getting Started Guide',
      description: 'A step-by-step guide to help you get started with our products.',
      link: require('../guides/getting_started.pdf'),
    },
    {
      title: 'Integration Guide',
      description: 'Learn how to seamlessly integrate our solutions into your existing systems.',
      link: require('../guides/integration.pdf'),
    },
    {
      title: 'Best Practices',
      description: 'Discover best practices for optimizing your use of our technologies.',
      link: require('../guides/best_practices.pdf'),
    },
  ];

  return (
    <div className="container mx-auto my-32 w-full">
      <h1 className="text-4xl font-bold text-center mt-10 mb-12">Guides and Tutorials</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {guides.map((guide, index) => (
          <div key={index} className="bg-white rounded-lg shadow-lg p-8">
            <h2 className="text-2xl font-semibold mb-4">{guide.title}</h2>
            <p className="text-gray-700 mb-6">{guide.description}</p>
            <a href={guide.link} className="text-blue-500 hover:text-blue-700" target="_blank" rel="noopener noreferrer">
              Read Guide
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GuidesPage;
