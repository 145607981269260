import React from 'react';

const TermsPage = () => {
  return (
    <div className="container mx-auto my-32 w-full text-xl px-2">
      <h1 className="text-2xl md:text-4xl font-bold text-center mt-10 mb-12">Terms</h1>
      <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
      <p className="mb-6">
        By accessing and using this website, you agree to comply with and be bound by these Terms and Conditions. If you do not agree to these terms, please do not use this website.
      </p>
      <h2 className="text-2xl font-semibold mb-4">2. Intellectual Property</h2>
      <p className="mb-6">
        All content and materials available on this website, including but not limited to text, graphics, logos, images, videos, and software, are the property of SkyFalke or its licensors and are protected by intellectual property laws.
      </p>
      <h2 className="text-2xl font-semibold mb-4">3. Privacy</h2>
      <p className="mb-6">
        We respect your privacy and handle your personal information in accordance with our Privacy Policy. By using this website, you consent to the collection, use, and disclosure of your information as described in our Privacy Policy.
      </p>
      <h2 className="text-2xl font-semibold mb-4">4. Limitation of Liability</h2>
      <p className="mb-6">
        SkyFalke shall not be liable for any direct, indirect, incidental, consequential, or exemplary damages resulting from your use of this website or any information or materials provided on the website.
      </p>
      <h2 className="text-2xl font-semibold mb-4">5. Governing Law</h2>
      <p className="mb-6">
        These Terms and Conditions shall be governed by and construed in accordance with the laws of Kenya.
      </p>
      <h2 className="text-2xl font-semibold mb-4">6. Modifications</h2>
      <p className="mb-6">
        SkyFalke reserves the right to modify or revise these Terms and Conditions at any time. Your continued use of this website after any changes are made constitutes your acceptance of the revised Terms and Conditions.
      </p>
      <h2 className="text-2xl font-semibold mb-4">7. Contact Us</h2>
      <p className="mb-6">
        If you have any questions about these Terms and Conditions, please contact us at <span className='italic font-mono'>legals@skyfalke.com</span>.
      </p>
    </div>
  );
};

export default TermsPage;
