import React, { useState, useEffect } from 'react';
import axios from "axios";
import {baseURL} from "../utils/constant";
import { PropagateLoader } from 'react-spinners';
import { css } from '@emotion/react';

const LearnMore = () => {
  const [product, setProduct] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const url = window.location.href;
    const parts = url.split('/');
    const productID = parts[parts.length - 1];

    async function fetchProduct() {
      try {
        const res = await axios.post(`${baseURL}/getExactSolution`, { productID });
        setProduct(res.data.data);
        setLoading(false);
      } catch (error) {
        alert('Error getting this product');
      }
    }

    if (productID) {
      fetchProduct();
    }
  }, []);

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '50vh', 
  };

  const override = css`
    display: block;
    margin: 0 auto;
  `;

  return (
    <div className="bg-gray-100 min-h-screen py-8 my-32 text-xl ">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-12">
          <div className="max-w-3xl mx-auto">
            <h1 className="text-3xl sm:text-4xl font-semibold mb-6">
              Our Product
            </h1>
            { loading ? (
            <div style={containerStyle}>
              <PropagateLoader css={override} color="#36D7B7" loading={loading} />
            </div>
            ) : 
               product && product.map((item)=>(
              <>
                  <p className="text-lg text-gray-700 mb-8" dangerouslySetInnerHTML={{ __html: item.description }} />                  
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                  <div>
                    <img
                      src={`../webimages/${item.thumnail}`}
                      alt="Product"
                      className="w-full rounded-lg shadow-md"
                    />
                  </div>
                  <div>
                    <h2 className="text-xl sm:text-2xl font-semibold mb-4">
                      Key Features
                    </h2>
                    <div className="list-disc list-inside text-gray-700">
                      {item.features}
                    </div>
                  </div>
                </div>
                <div className="mt-12">
                <h2 className="text-xl sm:text-2xl font-semibold mb-4">
                  How It Works
                </h2>
                <p className="text-gray-700">
                  {item.howItWorks}
                </p>
              </div>
              <div className="mt-12">
                <h2 className="text-xl sm:text-2xl font-semibold mb-4">
                  Benefits
                </h2>
                <div className="list-disc list-inside text-gray-700">
                  {item.benefits}
                </div>
              </div>
            </>
            ))}                  
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnMore;
