import React, {useState} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AiOutlineMessage } from "react-icons/ai";
import Index from './components/Index';
import './custom.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import About from './components/About';
import Press from './components/Press';
import Jobs from './components/Jobs';
import Company from './components/Company';
import Support from './components/Support';
import ContactUs from './components/ContactUs';
import Claims from './components/Claims';
import Explore from './components/Explore';
import Pricing from './components/Pricing';
import Partners from './components/Partners';
import Documentation from './components/Documentation';
import Guides from './components/Guides';
import Marketing from './components/Marketing';
import Analytics from './components/Analytics';
import Commerce from './components/Commerce';
import Data from './components/Data';
import Cloud from './components/Cloud';
import Policies from './components/Policies';
import Terms from './components/Terms';
import Conditions from './components/Conditions';
import SignUpPage from './components/Signup';
import NotFoundPage from './components/NotFound';
import BlogPage from './components/BlogPost';
import LearnMore from './components/LearnMore';
import Sidebar from './components/Admin/Sidebar';
import Home from './components/Admin/Home';
import Blogs from './components/Admin/Blog';
import Solutions from './components/Admin/solutions';
import Clients from './components/Admin/clients';
import Subscribers from './components/Admin/subscribers';
import AddBlog from './components/Admin/addBlog';
import AddSolution from './components/Admin/AddSolution';
import AdminSignIn from './components/Admin/SignIn';
import AdminSignUp from './components/Admin/Signup';
import TicketMessages from './components/Admin/TicketMessages';
import CallToAction from './components/Admin/CallToAction';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import Auth from './components/Auth';
import ChatModal from './components/ChatModal';
import PrintingService from './components/3DPrinting';
import Hosting from './components/Hosting';
import Shop from './components/Shop';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/signin" element={<Auth />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/admin/*" element={<Admin />} />
        <Route path="/admin/signup" element={<AdminSignUp />} />
        <Route path="/admin/signin" element={<AdminSignIn />} />
        <Route path="/*" element={<MainContent />} />        
      </Routes>
    </Router>
  );
}

const MainContent = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/press" element={<Press />} />
        <Route path="/3D-printing" element={<PrintingService />} />
        <Route path="/company" element={<Company />} />
        <Route path="/support" element={<Support />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/claims" element={<Claims />} />
        <Route path="/products" element={<Explore />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/documentation" element={<Documentation />} />
        <Route path="/guides" element={<Guides />} />
        <Route path="/marketing" element={<Marketing />} />
        <Route path="/web-hosting" element={<Hosting />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/commerce" element={<Commerce />} />
        <Route path="/data" element={<Data />} />
        <Route path="/cloud" element={<Cloud />} />
        <Route path="/policies" element={<Policies />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/conditions" element={<Conditions />} />
        <Route path="/blog/:id" element={<BlogPage />} />
        <Route path="/learn-more/:id" element={<LearnMore />} />        
        <Route path="/*" element={<NotFoundPage />} />        
      </Routes>
      <AiOutlineMessage
        className="chat cursor-pointer  p-3 fixed
          bottom-20 right-4 h-16 w-16 z-50 rounded-full"
        onClick={() => setOpen(!open)}
      />
      {open && <ChatModal />}
      <Footer />
    </>
  );
};

const Admin = () => {
  const  loggedIn  = localStorage.getItem('adminLoggedIn'); 

  if (!loggedIn) {
    return <AdminSignIn/>;
  }

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-1">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/newsletter-subscribers" element={<Subscribers />} />
          <Route path="/add-blog" element={<AddBlog />} />
          <Route path="/add-solution" element={<AddSolution />} />
          <Route path="/ticket-messages" element={<TicketMessages />} />
          <Route path="/calltoaction-requests" element={<CallToAction />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
