import React from 'react';

const DataPage = () => {
  return (
    <div className="container mx-auto my-32 text-xl w-full">
      <h1 className="text-4xl font-bold text-center mt-10 mb-12">Data Solutions</h1>
      <p className="text-center text-gray-700 mb-12">
        We offer a range of data solutions to help businesses make the most out of their data.
        Whether you need data analytics, data management, or data integration services, we have you covered.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div className="bg-white rounded-lg shadow-lg p-8">
          <h2 className="text-2xl font-semibold mb-4">Data Analytics</h2>
          <p className="text-gray-700 mb-6">
            Leverage the power of data analytics to gain valuable insights and make data-driven decisions.
            Our team of experts will help you analyze and interpret your data to unlock its full potential.
          </p>
          <a href='./learn-more' className="text-blue-500 hover:text-blue-700">
            Learn More
          </a>
        </div>
        <div className="bg-white rounded-lg shadow-lg p-8">
          <h2 className="text-2xl font-semibold mb-4">Data Management</h2>
          <p className="text-gray-700 mb-6">
            Efficiently store, organize, and manage your data with our advanced data management solutions.
            We provide secure and scalable data infrastructure to ensure data integrity and availability.
          </p>
          <a href='./learn-more' className="text-blue-500 hover:text-blue-700">
            Learn More
          </a>
        </div>
        <div className="bg-white rounded-lg shadow-lg p-8">
          <h2 className="text-2xl font-semibold mb-4">Data Integration</h2>
          <p className="text-gray-700 mb-6">
            Seamlessly connect and integrate your data from various sources with our data integration services.
            We enable smooth data flow and interoperability to enhance your business processes and decision-making.
          </p>
          <a href='./learn-more' className="text-blue-500 hover:text-blue-700">
            Learn More
          </a>
        </div>
      </div>
    </div>
  );
};

export default DataPage;
