import React, { useState, useRef  } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios'
import {baseURL } from "../../utils/constant";
import { FaSpinner } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';

const AddProducts = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [benefits, setBenefits] = useState('');
  const [features, setFeatures] = useState('');
  const [howItWorks, setHowItWorks] = useState('');
  const [thumbnail, setThumbnail] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleThumbnailUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setThumbnail(file);
    }
  };  

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['blockquote', 'code-block'],
      [{ color: [] }, { background: [] }],
      ['link', 'image'],
      ['clean'],
    ],
  };
  

  const descriptionQuillRef = useRef(null);
 

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title || !description || !benefits || !features || !howItWorks || !thumbnail) {
      toast.error('All fields are required.', {
        position: toast.POSITION.TOP_CENTER
      });
      return;
    }
  
    // Create a FormData object to handle file uploads
    setLoading(true);
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('benefits', benefits);
    formData.append('features', features);
    formData.append('howItWorks', howItWorks);
    formData.append('thumbnail', thumbnail);
  
    try {
      await axios.post(`${baseURL}/add-product`, formData);  
      toast.success('Product added successfully!', {
        position: toast.POSITION.TOP_CENTER
      });
      setLoading(false);
    } catch (error) {
      toast.error('Error adding blog.', {
        position: toast.POSITION.TOP_CENTER
      });
      console.error('Error adding blog:', error); 
      setLoading(false); 
    }
  };

  return (
    <>
    <ToastContainer />
    <div className="p-6 my-6">
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="title" className="block text-sm font-medium text-gray-700">
            Solution Title
          </label>
          <input
            type="text"
            id="title"
            name="title"
            value={title}
            onChange={handleTitleChange}
            className="mt-1 p-2 w-full border rounded-md focus:ring focus:ring-indigo-300"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="thumbnail" className="block text-sm font-medium text-gray-700">
            Thumbnail
          </label>
          <input
            type="file"
            id="thumbnail"
            name="thumbnail"
            accept="image/*"
            onChange={handleThumbnailUpload}
            className="mt-1 p-2 border rounded-md focus:ring focus:ring-indigo-300"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="description" className="block font-medium text-gray-700">
            Description
          </label>
          <ReactQuill
          ref={descriptionQuillRef}
          value={description}
          onChange={(e)=>setDescription(e.target.value)}
          modules={modules}
          className="border rounded focus:ring focus:ring-indigo-300"
        />
        </div>
        <div className="mb-4">
          <label htmlFor="description" className="block font-medium text-gray-700">
            Key Features
          </label>
          <textarea className='mt-1 p-2 w-full border rounded-md focus:ring focus:ring-indigo-300' value={features} onChange={(e)=>setFeatures(e.target.value)} cols="5" rows="4"></textarea>          
        </div>
        <div className="mb-4">
          <label htmlFor="howitworks" className="block font-medium text-gray-700">
            How it Works
          </label>
          <textarea className="mt-1 p-2 w-full border rounded-md focus:ring focus:ring-indigo-300" value={howItWorks} onChange={(e)=>setHowItWorks(e.target.value)} cols="5" rows="4"></textarea>          
        </div>
        <div className="mb-4">
          <label htmlFor="benefits" className="block font-medium text-gray-700">
            Benefits
          </label>
          <textarea className="mt-1 p-2 w-full border rounded-md focus:ring focus:ring-indigo-300" value={benefits} onChange={(e)=>setBenefits(e.target.value)} cols="5" rows="4"></textarea>          
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
            disabled={loading} 
          >
            {loading && (
                  <FaSpinner className='animate-spin mx-auto' />
              )}
              {!loading && 'Submit'}
          </button>
        </div>
      </form>     
    </div>
    </>
  );
};

export default AddProducts;
