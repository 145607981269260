import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';
import axios from 'axios';
import { baseURL } from '../../utils/constant';
import logo from '../../assets/logo2.png';
/*import { FcGoogle } from "react-icons/fc"; */
/*import { GoogleLogin } from 'react-google-login'; */


const SignInPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  /*const handleGoogleSignUp = (googleData) => {
    
    const profile = googleData.profileObj; 
    const idToken = googleData.tokenId;
    const tokenId = idToken;
    console.log('Google User Profile:', profile);
  console.log('Google ID Token:', idToken);
    axios
      .post(`${baseURL}/google-login`, { token: tokenId })
      .then((response) => {
        if (response.data.status === 'ok') {        
          window.localStorage.setItem('token', response.data.data);
          window.localStorage.setItem('loggedIn', 'true');
          setTimeout(() => {
            setLoading(false);
          }, 2000);
          window.location.href = '/';
        } else {          
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error('Google Sign-In failed:', error);
        setLoading(false);
      });
  }; */

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

     //Validations
     const validationErrors = {};
     if (!name) {
         validationErrors.name = 'Name is required';
     }
     if (!email) {
         validationErrors.email = 'Email is required';
     } else if (!/\S+@\S+\.\S+/.test(email)) {
         validationErrors.email = 'Invalid email address';
     }
     if (!password) {
         validationErrors.password = 'Password is required';
     } else if (password.length < 6) {
         validationErrors.password = 'Password must be at least 6 characters long';
     }

    if (Object.keys(validationErrors).length === 0) {
        const payload = {
          email,
          name,
          password
      }
      try {
        await axios.post(`${baseURL}/adminSignup`, payload).then((response) => { 
            setEmail('');
            setPassword('');
            setName('');
            setErrors({});
            setLoading(false);
            setSuccess(true);                
        });
      }
      catch (e) {
        setErrors(validationErrors);
        setLoading(false);
      }
    } else {
        // Form is invalid, update errors state
        setErrors(validationErrors);
        setLoading(false);
    }
}

useEffect(()=> {
  document.title = "SkyFalke Admin | Signup";
});

  return (
    <div className="min-h-screen bg-gray-100">
      <nav className="flex items-center justify-between px-4 py-5 bg-transparent top-0 fixed">
        <div>
          <Link to='/'><img src={logo} alt="Logo" className="h-8" /></Link>
        </div>
      </nav>
      <div className="flex items-center justify-center min-h-screen mx-auto px-2">
        <div className="max-w-md w-full p-6 bg-white rounded-md shadow-lg">
          <h2 className="mb-2.5 text-3xl font-bold text-navy-700 dark:text-white">Sign Up</h2>
          <p className="mb-9 ml-1 text-base text-gray-400">Enter your email and password to sign up!</p>
          
        {success && (
            <div className="bg-green-100 border-l-4 border-green-500 p-4 mb-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg
                    className="h-5 w-5 text-green-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2.293 9.293a1 1 0 011.414 0L9 14.586l6.293-6.293a1 1 0 111.414 1.414l-7 7a1 1 0 01-1.414 0l-7-7a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-green-700">
                    Sign up successful! You can now <Link to="/signin" className="text-blue-500 hover:text-blue-600">Sign In</Link>.
                  </p>
                </div>
              </div>
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block mb-2 font-semibold text-navy-700 text-navy-700 " htmlFor="email">Email*</label>
              <input
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                type="email"
                id="email"
                name="email"
                value={email}
                placeholder="mail@example.com"
                onChange={handleEmailChange}
                required
                autoFocus
              />
              {errors.email && <span className="text-red-500">{errors.email}</span>}
            </div>
            <div className="mb-6">
              <label className="block mb-2 font-semibold" htmlFor="password">Name</label>
              <input
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={handleNameChange}
                placeholder="John Doe"
                required
              />
              {errors.name && <span className="text-red-500">{errors.name}</span>}
            </div>
            <div className="mb-6">
              <label className="block mb-2 font-semibold" htmlFor="password">Password*</label>
              <input
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Min. 8 characters"
                required
              />
              {errors.password && <span className="text-red-500">{errors.password}</span>}
            </div>            
            <div className="flex justify-center">
              <button
                className="linear mt-2 w-full rounded-xl bg-blue-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-blue-600 hover:text-white active:bg-blue-700"
                type="submit"
                disabled={loading} 
              >
                 {loading && (
                        <FaSpinner className='animate-spin mx-auto' />
                    )}
                    {!loading && 'Sign Up'}
              </button>
            </div>
          </form>          
       
          <div className="mt-4">
              Already have an account? <Link to="../admin/signin" className="text-blue-500 hover:text-blue-600">Sign In</Link>
          </div>          
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
