import React from 'react';
import HostingImage from '../assets/cloudHosting.jpg';

const HostingService = () => {
  // const handleContactUs = () => {
  //   const email = 'your@email.com'; // Replace with your email address
  //   const subject = encodeURIComponent('Inquiry about Hosting Service');
  //   const body = encodeURIComponent('I am interested in your web hosting service. Please provide me with more information.');

  //   window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
  // };

  return (
    <div className="bg-gray-100 rounded-lg shadow-md flex flex-col md:flex-row items-center my-32 w-full mx-auto p-10">
      <div className="md:w-1/2 mb-4 md:mb-0">
          <img src={HostingImage} alt="Hosting Service" className="w-full h-auto rounded-md" />
      </div>
      <div className="md:w-1/2 md:ml-6">
        <h2 className="text-2xl share-tech-regular  mb-4">Web Hosting Service</h2>
        <p className="text-gray-700 mb-4">
          We offer reliable and affordable web hosting solutions for businesses and individuals. Whether you're launching a new website or migrating an existing one, our hosting service provides the performance, security, and support you need.
        </p>
        <ul className="list-disc pl-5 mb-4">
          <li>99.9% uptime guarantee</li>
          <li>24/7 technical support</li>
          <li>Scalable resources to accommodate your growth</li>
          <li>Secure hosting environment</li>
        </ul>
        {/* <button
          onClick={handleContactUs}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Contact Us
        </button> */}
        <p className='text-red-600 font-bold'>Coming Soon</p>
      </div>
    </div>
  );
};

export default HostingService;
