import React from 'react'
import FAQSection from './FAQSection'

const Support = () => {
  return (
    <div name='platforms' className='w-full my-32'>
    <div className='max-w-[1240px] mx-auto px-2 mt-10 text-xl text-left'>
      <div className='mt-4'>
          <h2 className='font-bold text-center text-3xl'>SkyFalke Support Center</h2>
          <div className='mt-4'>
              <div>
                <p>We understand that technology is an integral part of your business, and we are here to ensure that you have a seamless and hassle-free experience with our products and services. Our support team is committed to providing you with exceptional assistance whenever you need it.</p>

                <h3 className='font-bold'>Our Support Philosophy:</h3>
                    <ol>
                        <li>1. <span className='italic'>Customer-Centric Approach:</span> Your satisfaction is our top priority. We take the time to listen to your concerns and provide personalized solutions that meet your specific needs. Our goal is to ensure that you have a positive experience with our support team.</li>
                        <li>2. <span className='italic'>Expertise and Knowledge:</span> Our support team consists of highly skilled professionals who have in-depth knowledge of our products and services. They undergo continuous training to stay up-to-date with the latest technological advancements, enabling them to provide you with accurate and effective solutions.</li>
                        <li>3. <span className='italic'>Prompt and Efficient Service:</span> We understand that time is of the essence when you encounter technical issues. Our support team is dedicated to responding to your inquiries promptly and efficiently, minimizing any disruptions to your business operations.</li>
                        <li>4. <span className='italic'>Clear Communication:</span> We believe in transparent and open communication. Our support team will guide you through the troubleshooting process, explaining technical concepts in a clear and understandable manner. We want you to feel empowered and confident in resolving any issues you may encounter.</li>
                        <li>5. <span className='italic'>Continuous Improvement:</span> We are committed to continuous improvement and strive to enhance our support services based on your feedback. We value your input and use it to refine our processes, ensuring that we consistently deliver exceptional support experiences.</li>
                    </ol>

                    <h2 className='font-bold '>Our Support Services:</h2>
                        <ol>
                            <li>1. Technical Assistance: Whether you need help with software installation, configuration, or troubleshooting, our support team is here to assist you. We'll work with you to diagnose and resolve any technical issues, ensuring that your systems are running smoothly.</li>
                            <li>2. Product Updates and Upgrades: As technology evolves, we provide regular updates and upgrades to our products to enhance their functionality and security. Our support team will guide you through the update process, addressing any concerns or questions you may have.</li>
                            <li>3. Knowledge Base and FAQs: To empower our customers, we maintain a comprehensive knowledge base and frequently asked questions (FAQs) section. Here, you'll find valuable resources, tutorials, and troubleshooting guides to help you find answers to common queries.</li>
                            <li>4. Remote Support: In some cases, our support team can remotely access your systems to diagnose and resolve issues efficiently. This remote support option saves you time and minimizes any disruptions to your business operations.</li>
                            <li>5. Customer Feedback: We value your feedback and encourage you to share your thoughts and suggestions. Your input helps us enhance our support services and ensure that we continue to meet and exceed your expectations.</li>
                        </ol>
                        <h2 className='text-center font-bold mt-4 text-2xl'>Contact Our Support Team</h2>
                        <div class="grid grid-cols-1 gap-6 sm:grid-cols-3">
                            <div class="bg-white rounded-lg shadow-md p-6 text-center">
                                <svg class="w-12 h-12 mx-auto mb-4 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 22C6.47 22 2 17.53 2 12 2 6.47 6.47 2 12 2s10 4.47 10 10c0 5.53-4.47 10-10 10zm0-18a8 8 0 1 0 0 16v-2a4.01 4.01 0 0 1-4-4V8a4.01 4.01 0 0 1 4-4z"></path>
                                </svg>
                                <h3 class="text-lg font-semibold mb-2">Phone Support</h3>
                                <p class="text-gray-700">Call our dedicated support hotline, and our knowledgeable representatives will assist you.</p>
                            </div>
                            
                            <div class="bg-white rounded-lg shadow-md p-6 text-center">
                                <svg class="w-12 h-12 mx-auto mb-4 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5z"></path>
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 14v2a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2"></path>
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 10l4.553-2.776a1 1 0 0 1 .894 0L21 10m-6-9v2m4 0v2m-8-2V3m0 4V5"></path>
                                </svg>
                                <h3 class="text-lg font-semibold mb-2">Email Support</h3>
                                <p class="text-gray-700">Send us an email outlining your query or concern, and our team will respond promptly.</p>
                            </div>
                            
                            <div class="bg-white rounded-lg shadow-md p-6 text-center">
                            <svg class="w-12 h-12 mx-auto mb-4 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 22c5.523 0 10-4.477 10-10V4c0-5.523-4.477-10-10-10S2 4.477 2 10v8c0 5.523 4.477 10 10 10zM12 16V9m0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"></path>
    </svg>
                                <h3 class="text-lg font-semibold mb-2">Online Chart</h3>
                                <p class="text-gray-700"> Engage with our support team in real-time via our online chat feature on our website.</p>
                            </div>
                        </div>

                        <h2 className='text-center font-bold mt-4 text-3x mb-8'>FAQS</h2>
                        <FAQSection />
                </div>
          </div>
      </div>
  </div>
  </div>
  )
}

export default Support