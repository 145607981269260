import React, { useState, useEffect } from 'react';
import { UserIcon, ShoppingCartIcon, CurrencyDollarIcon  } from '@heroicons/react/solid'; 
import AvatarImage from '../../assets/auth.png';
import { baseURL } from '../../utils/constant'
import axios from 'axios';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [callToAction, setCallToAction] = useState("");
  const [totalUsers, setTotalUsers] = useState("");
  const [messages, setMessages] = useState("");
  const [userData, setUserData] = useState("");

  const token = localStorage.getItem("token");

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () =>{
    localStorage.removeItem("adminLoggedIn");
    localStorage.removeItem("token");
  }

  useEffect(()=>{
    async function getUserDetails(){
      const res = await axios.get(`${baseURL}/getAdminData`, {
        headers: {
          Authorization: `Bearer ${token}` 
        }
      });
      if(res.data.status === "ok"){
        setUserData(res.data.data);
      }
    }
    async function getCallToAction(){
      const callToAction = await axios.get(`${baseURL}/getCallToAction`);
      if(callToAction.data.status === "ok"){
        setCallToAction(callToAction.data.data);
      }
    };
    async function getUsers(){
      const users = await axios.get(`${baseURL}/getUsers`);
      if(users.data.status === "ok"){
        setTotalUsers(users.data.data);
      }
    }
    async function getMessages(){
      const msgs = await axios.get(`${baseURL}/getMessages`);
      if(msgs.data.status === "ok"){
        setMessages(msgs.data.data);
      }
    }

    getUserDetails();
    getCallToAction();
    getUsers();
    getMessages();
  }, [token]);

  return (
    <div className="flex h-screen">
      <div className="flex-1 flex flex-col overflow-hidden">    
        <nav className="bg-white border-b border-gray-200 p-4">
          <div className="mx-auto">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">                            
              </div>
              <div className="flex items-center space-x-4 relative">
                {
                  userData && userData.map((item)=>(
                    <>
                      <p className='font-bold'>Hello, {item.username}</p>
                      <button
                        onClick={toggleDropdown}
                        className="relative focus:outline-none"
                      >
                        <img src={AvatarImage} alt="User Avatar" className="w-8 h-8 rounded-full" />
                        {isDropdownOpen && (
                          <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 shadow-lg rounded-md z-10">
                            <ul className="py-2">
                              <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                                <a href="./profile">Profile</a>
                              </li>
                              <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                                <a href="./settings">Settings</a>
                              </li>
                              <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                                <button className='bg-transparent' onClick={handleLogout}>Sign Out</button>
                              </li>
                            </ul>
                          </div>
                        )}
                      </button>
                    </>
                  ))
                }
              </div>
            </div>
          </div>
        </nav>

        {/* Page Content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-8">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div className="bg-white p-4 shadow-md rounded-lg">
                <div className="flex items-center">
                  <UserIcon className="w-6 h-6 text-blue-500 mr-2" />
                  <div>
                    <h2 className="text-lg font-semibold mb-2">Total Users</h2>
                    <p className="text-gray-600">{totalUsers} users</p>
                  </div>
                </div>
              </div>
              <div className="bg-white p-4 shadow-md rounded-lg">
                <div className="flex items-center">
                  <ShoppingCartIcon className="w-6 h-6 text-green-500 mr-2" />
                  <Link to='calltoaction-requests'>
                    <div>
                      <h2 className="text-lg font-semibold mb-2">Call To Action</h2>
                      <p className="text-gray-600">{callToAction} requests</p>
                    </div>
                  </Link>                  
                </div>
              </div>
              <div className="bg-white p-4 shadow-md rounded-lg">
                <div className="flex items-center">
                  <CurrencyDollarIcon className="w-6 h-6 text-yellow-500 mr-2" />
                    <Link to='./ticket-messages'>
                      <div>
                        <h2 className="text-lg font-semibold mb-2">Ticket Messages</h2>
                        <p className="text-gray-600">{messages} New Messages</p>
                      </div>
                    </Link>                  
                </div>
              </div>
            </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
