import React from 'react'
import IndexAbout from './IndexAbout';
import AllInOne from './AllInOne';
import Support from './IndexSupport'
import Hero from './Hero';
import ClientLogos from './Clients';
import CallToAction from './CallToAction';
import LatestNews from './LatestNews';


const Index = () => {
  return (
    <>
      <Hero />
      <IndexAbout />
      <Support />
      <AllInOne />
      <ClientLogos />
      <LatestNews />
      <CallToAction />
    </>
  )
}

export default Index