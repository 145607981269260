import React from 'react'

const Jobs = () => {
  return (
    <div name='platforms' className='w-full my-32'>
    <div className='max-w-[1240px] mx-auto px-2 mt-10 text-xl text-left'>
      <div className='mt-4'>
          <h2 className='font-bold font-4xl font-sans'>Our Culture</h2>
          <p className='mt-3'>At SkyFalke, we foster a dynamic and inclusive work environment that promotes innovation, collaboration, and professional growth. We believe in empowering our staff and providing them with the opportunities and resources to excel in their careers. We encourage a culture of open communication, creativity, and a passion for technology.</p>
      </div>
      <div className='mt-4'>
          <h2 className='font-bold'>Job Opportunities</h2>
          <ol className='mt-3'>The following are job positions currently available:
            <li>1. Software Development</li>
            <li>2. Data Science and Analytics</li>
            <li>3. Network Administration</li>
            <li>4. UI/UX Design</li>
            <li>5. Sales and Marketing</li>
            <li>6. Customer Support</li>
          </ol>
      </div>
      <div className='mt-4'>
          <h2 className='font-bold'>Job Descriptions</h2>
          <div>

          </div>
      </div>
      <div className='mt-4'>
          <h2 className='font-bold'>Employee Benefits and Perks:</h2>
          <ol className='mt-4'>
              <li>(i). Competitive salary and performance-based incentives</li>
              <li>(ii). Health and wellness programs</li>
              <li>(iii). Flexible work hours or remote work opportunities</li>
              <li>(iv). Professional development and training programs</li>
              <li>(v). Team-building activities and social events</li>
              <li>(vi). Modern and comfortable workspaces</li>
          </ol>
      </div>
      <div className='mt-4'>
      <h2 className='mb-2'>Application Process:</h2>
        <p>To apply for a position at SkyFalke, please follow the steps below:</p>
            <ol>
                <li>
                    <h3 className='font-bold italic'>A. Review Job Openings:</h3>
                    <p>Browse through our current job openings listed below and select the position that matches your skills and interests.</p>
                </li>
                <li>
                    <h3 className='font-bold italic'>B. Prepare Application Documents:</h3>
                    <p>Please ensure you have the following documents ready:</p>
                    <ol>
                        <li>1. Resume/CV: Provide a detailed summary of your education, work experience, and relevant skills.</li>
                        <li>2. Cover Letter (Optional): Share your motivation for applying to SkyFalke and highlight how your qualifications align with the specific role.</li>
                    </ol>
                </li>
                <li>
                    <h3 className='font-bold italic' >C. Submit Your Application:</h3>
                    <p>Send an email to <span className='font-bold text-blue-600'>careers@skyfalke.com</span> with the subject line "Job Application: [Position Title]." Attach your resume/CV and cover letter (if applicable) in PDF or Word format. </p>
                </li>
                <li>
                    <h3 className='font-bold italic'>D. Application Review:</h3>
                    <p>Our recruitment team will carefully review your application and assess your qualifications in relation to the position requirements. We appreciate your patience during this process, as we strive to review applications in a timely manner.</p>
                </li>
                <li>
                    <h3 className='font-bold italic'>C. Interviews:</h3>
                    <p>If your application matches our criteria, we will contact you to schedule an initial interview. Depending on the position, this may be conducted via video call or in-person at our office. Subsequent interviews may be conducted with additional team members or senior leadership.</p>
                </li>
                <li>
                    <h3 className='font-bold italic'>D. Selection and Offer:</h3>
                    <p>After the interview process, the most qualified candidate will be selected for the position. If you are chosen, we will extend a job offer to you, which will include details regarding compensation, benefits, and start date. We may also request references or conduct background checks as part of our standard hiring process.</p>
                </li>
                <li>
                    <h3 className='font-bold italic'>E. Onboarding and Welcome:</h3>
                    <p>Once you accept our job offer, our HR team will guide you through the onboarding process. You will receive information about our company policies, complete necessary paperwork, and have the opportunity to connect with your team members. We strive to create a warm and welcoming environment for all new hires.</p>
                </li>
            </ol>
            <p className='mt-2'>If you have any further questions or require additional information during the application process, please feel free to contact us at <span className='font-bold text-blue-600'>careers@skyfalke.com</span> or call <span className='font-bold'>0717797238</span>.</p>
            <p className='mt-3' >Please note that due to the high volume of applications we receive, we will only contact candidates who are selected for an interview. However, we appreciate your interest in SkyFalke and thank you for considering us as your potential employer.</p>
      </div>
      <p className='mt-4 italic'>*SkyFalke is committed to diversity, inclusivity, and providing equal employment opportunities to all qualified individuals regardless of race, color, religion, sex, national origin, age, disability, or any other protected status.*</p>
  </div>
  </div>
  )
}

export default Jobs