import React from 'react';
import { Link } from 'react-router-dom';

const Shop = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 w-full my-32">
      <h1 className="text-4xl font-bold text-green-600 mb-6">Coming soon...</h1>
    </div>
  );
};

export default Shop;
