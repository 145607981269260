import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { baseURL } from '../utils/constant';
import { FaFacebook, FaGithub, FaInstagram, FaTwitter, FaYoutube, FaSpinner, FaSpotify } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubscribe = async (e) => {
    e.preventDefault();
    if (!email) {
      toast.error('Please enter your email.', {
        position: toast.POSITION.TOP_CENTER
      });
      return;
    }
    try {
      setLoading(true); 
      const res = await axios.post(`${baseURL}/addNewsletterSubscriber`, { email });
      console.log(res);
      if (res.data.status === 'ok') {
        toast.success('Thank you!', {
          position: toast.POSITION.TOP_CENTER
        });
      } else if (res.data.status === 'exists') {
        toast.error('Email in use by someone else.', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    } catch (error) {
      toast.error('An error occured. Please try again.', {
        position: toast.POSITION.TOP_CENTER
      });
      console.log('Error:', error);
    } finally {
      setLoading(false); 
    }
  };
 
  return (
    <>
    <ToastContainer />
    <div className='w-full mt-24 bg-slate-900 text-gray-300 py-y px-2 bottom-0'>
        <div className='max-w-[1240px] mx-auto grid grid-cols-2 md:grid-cols-6 border-b-2 border-gray-600 py-8'>
            <div>
                <h6 className='font-bold uppercase pt-2'>Solutions</h6>
                <ul>
                    <li className='py-1'><Link to="marketing">Marketing</Link></li>
                    <li className='py-1'><Link to="analytics">Analytics</Link></li>
                    <li className='py-1'><Link to="commerce">Commerce</Link></li>
                    <li className='py-1'><Link to="data">Data</Link></li>
                    <li className='py-1'><Link to="cloud">Cloud</Link></li>
                </ul>
            </div>
            <div>
                <h6 className='font-bold uppercase pt-2'>Support</h6>
                <ul>
                    {/* <li className='py-1'><Link to='/pricing'>Pricing</Link></li> */}
                    <li className='py-1'><Link to="/documentation">Documentation</Link></li>
                    <li className='py-1'><Link to="/guides">Guides</Link></li>
                    <li className='py-1'><Link to="/support">Support</Link></li>
                    <li className='py-1'><Link to="/contact-us">Contact Us</Link></li>
                </ul>
            </div>
            <div>
                <h6 className='font-bold uppercase pt-2'>Company</h6>
                <ul>
                    <li className='py-1'><Link to='/company'>Company</Link></li>
                    {/* <li className='py-1'><Link to="/news">News</Link></li> */}
                    <li className='py-1'><Link to='/jobs'>Jobs</Link></li>
                    <li className='py-1'><Link to='/press'>Press</Link></li>
                    <li className='py-1'><Link to="/partners">Partners</Link></li>
                </ul>
            </div>
            <div>
                <h6 className='font-bold uppercase pt-2'>Legal</h6>
                <ul>
                    <li className='py-1'><Link to="/claims">Claims</Link></li>
                    <li className='py-1'><Link to="/terms">Terms</Link></li>
                    <li className='py-1'><Link to="/policies">Policies</Link></li>
                    <li className='py-1'><Link to="/conditions">Conditions</Link></li>
                </ul>
            </div>
            <div className='col-span-2 pt-8 md:pt-2'>
                <p className='font-bold uppercase'>Subscribe to our newsletter</p>
                <p className='py-4'>The latest news, articles, and resources, sent to your inbox weekly.</p>
                <form className='flex flex-col sm:flex-row' onSubmit={handleSubscribe}>
                    <input
                    className='w-full p-2 mr-4 rounded-md mb-4 text-gray-800 font-bold'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type='email'
                    placeholder='Enter email...'
                    />
                    <button
                    className='py-2 px-4 mb-4 text-white bg-yellow-600 border-none hover:border-white hover:text-white relative'
                    type='submit'
                    disabled={loading} 
                    >
                    {loading && (
                        <FaSpinner className='animate-spin mr-2' />
                    )}                   
                    {!loading &&  'Subscribe'}
                    </button>
                </form>
            </div>
        </div>

        <div className='flex flex-col max-w-[1240px] px-2 py-4 mx-auto justify-between sm:flex-row text-center text-gray-500'>
        <p className='py-4'>©{1900 + new Date().getYear()} SkyFalke Group. All Rights Reserved.</p>
        <div className='flex justify-between sm:w-[300px] pt-4 text-2xl'>
            <a href="https://facebook.com/profile.php?id=100063715734043" target='blank'><FaFacebook /></a>
            <a href="https://instagram.com/skyfalke_kenya?utm_source=qr&r=nametag" target='blank'><FaInstagram /></a>
            <a href="https://twitter.com/skyfalke" target='blank'><FaTwitter /></a>
            <a href="https://youtube.com/@SkyFalke" target='blank'><FaYoutube /></a>
            <a href="https://github.com/skyfalkedev" target='blank'><FaGithub /></a>
            <a href="https://open.spotify.com/show/1O6FYdCgKZeVKuYxlIqjvF?si=ea6f15e4d2d14662" target='blank'><FaSpotify /></a>
        </div>
        </div>
    </div>
    </>
  );
}

export default Footer