import React from 'react';
import RotatingText from 'react-rotating-text';
import vid from '../assets/vid2.mp4';

const Hero = () => {
  const rotatingTextOptions = {
    items: ['Inspiring Innovation', 'Embracing Creativity', 'Accelerating Growth'],
    deletingInterval: 50,
    pauseInterval: 2500,
  };

  return (
    <>
      <div name='home' className='w-full h-screen relative overflow-hidden'>
        <video autoPlay loop muted playsInline className="video-bg absolute top-0 left-0 z-0 object-cover w-full h-full">
          <source src={vid} type="video/mp4" />
        </video>
        <div className='absolute top-0 left-0 z-10 w-full h-full bg-black opacity-40'></div>
        <div className='relative z-20 flex flex-col justify-center items-center w-full h-full text-white'>
          <p className='text-3xl md:text-5xl  roboto-medium '>Technology Redefined</p>
          <h1 className='text-3xl md:text-5xl roboto-medium  text-cyan-300'>
            <RotatingText
              items={rotatingTextOptions.items}
              typingInterval={rotatingTextOptions.typingInterval}
              deletingInterval={rotatingTextOptions.deletingInterval}
              pauseInterval={rotatingTextOptions.pauseInterval}
            />
          </h1>
          <p className='text-3xl md:text-5xl roboto-medium'>Transforming Industries.</p>
          {/* <Link to='skyfalke-verse'>
            <button className='mt-8 px-8 py-3 text-lg md:text-xl font-semibold bg-transparent border-white text-white rounded-full transition duration-300 ease-in-out transform hover:scale-105'>
              <span className="mr-2 roboto-medium">Explore Now</span>
              <svg className='inline-block' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.75 17.25L12 12L6.75 6.75" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12 12H5.25" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </button>
          </Link> */}
        </div>
        <a href="#more">
          <div className="scrollDown"></div>
        </a>
      </div>
    </>
  );
};

export default Hero;
