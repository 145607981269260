import React from 'react';

const MarketingPage = () => {
  return (
    <div className="container mx-auto my-32 w-full">
      <h1 className="text-4xl font-bold text-center mt-10 mb-12">Marketing Services</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        <div className="bg-white rounded-lg shadow-lg p-8">
          <h2 className="text-2xl font-semibold mb-4">Digital Marketing</h2>
          <p className="text-gray-700 mb-6">
            We offer comprehensive digital marketing services to help your business establish a strong online presence. From search engine optimization (SEO) to social media advertising, our team will create effective strategies tailored to your target audience.
          </p>
        </div>
        <div className="bg-white rounded-lg shadow-lg p-8">
          <h2 className="text-2xl font-semibold mb-4">Content Marketing</h2>
          <p className="text-gray-700 mb-6">
            Our content marketing services are designed to attract, engage, and convert your audience. We develop compelling and relevant content that aligns with your brand voice and business objectives, helping you build credibility and drive customer loyalty.
          </p>
        </div>
        <div className="bg-white rounded-lg shadow-lg p-8">
          <h2 className="text-2xl font-semibold mb-4">Email Marketing</h2>
          <p className="text-gray-700 mb-6">
            Reach your customers directly and effectively with our email marketing services. We create customized email campaigns that deliver personalized messages, drive customer engagement, and nurture leads, ultimately increasing conversions and sales.
          </p>
        </div>
        <div className="bg-white rounded-lg shadow-lg p-8">
          <h2 className="text-2xl font-semibold mb-4">Social Media Marketing</h2>
          <p className="text-gray-700 mb-6">
            Leverage the power of social media to expand your brand reach and engage with your target audience. Our social media marketing services encompass strategy development, content creation, community management, and paid advertising across various platforms.
          </p>
        </div>
        <div className="bg-white rounded-lg shadow-lg p-8">
          <h2 className="text-2xl font-semibold mb-4">Influencer Marketing</h2>
          <p className="text-gray-700 mb-6">
            Collaborate with influential personalities in your industry to promote your products or services. Our influencer marketing services connect you with relevant influencers, manage campaigns, and measure results, helping you gain exposure and build trust among your target audience.
          </p>
        </div>
        <div className="bg-white rounded-lg shadow-lg p-8">
          <h2 className="text-2xl font-semibold mb-4">Analytics and Reporting</h2>
          <p className="text-gray-700 mb-6">
            Measure the success of your marketing efforts with our analytics and reporting services. We provide in-depth analysis of key metrics, track campaign performance, and deliver comprehensive reports, enabling you to make data-driven decisions and optimize your marketing strategies.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MarketingPage;
