import React from 'react'

const IndexAbout = () => {
  return (
    <div name='about' className='w-full my-32 px-2' id='more'>
        <div className='max-w-[1240px] mx-auto'>
            <div className='text-center'>
                <h2 className='text-2xl md:text-4xl font-bold text-gray-900 share-tech-regular'>Trusted By Clients Across The Continent</h2>
                  <p className='text-2xl py-6 text-gray-500'>We have established ourselves as a reliable partner for businesses of all sizes, delivering solutions that drive growth and enhance efficiency.
                   Our team of experts combines technical expertise with a deep understanding of industry trends to deliver customized solutions tailored to meet your specific needs. </p>
            </div>
            <div className='grid md:grid-cols-3 gap-5 px-2 text-center'>
                <div className='border py-8 rounded-xl shadow-xl' >
                    <p className='text-6xl font-bold text-blue-600'>100%</p>
                    <p className='text-blue-600 mt-2'>Product Completion</p>
                </div>
                <div  className='border py-8 rounded-xl shadow-xl' >
                    <p className='text-6xl font-bold text-blue-600'>24/7</p>
                    <p className='text-blue-600 mt-2'>Customer Support</p>
                </div>
                <div className='border py-8 rounded-xl shadow-xl' >
                    <p className='text-6xl font-bold text-blue-600'>100%</p>
                    <p className='text-blue-600 mt-2'>Constant Maintenance</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default IndexAbout