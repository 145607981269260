import React, {useState} from 'react';
import axios from 'axios';
import { baseURL } from '../utils/constant';
import { FaSpinner } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';


const ContactUs = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [reason, setReason] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); 


  const handleCallToAction = async(e) => {
    e.preventDefault();
    setLoading(true);

    //Validations
    const validationErrors = {};
    if (!email) {
        validationErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
        validationErrors.email = 'Invalid email address';
    }
    if (!name) {
        validationErrors.name = 'Name is required';
    } 
    if (!message) {
      validationErrors.message = 'Please enter your message';
  } 
    if (!reason) {
    validationErrors.solution = 'Please choose a solution';
  } 
    if (Object.keys(validationErrors).length === 0) {
        // Form is valid, submit data to the server
        const payload = {
            email,
            name,
            message,
            reason
        }
        try {
            await axios.post(`${baseURL}/customer-message`, payload)
                .then((response) => {
                    if (response.data.status === "ok") {
                      toast.success('Thank you, We received you message successfully!', {
                        position: toast.POSITION.TOP_CENTER
                      });
                       setLoading(false);                      
                    } else {
                      toast.error('Message not sent. Please try again.', {
                        position: toast.POSITION.TOP_CENTER
                      });
                    }
                });
            setErrors({});
        }
        catch (e) {
            if (e.code === "ERR_NETWORK") {
              toast.error('It seems you not connected to Internet.', {
                position: toast.POSITION.TOP_CENTER
              });
            }
            setLoading(false);
        }
    } else {
        setErrors(validationErrors);
        setLoading(false);
    }
  }


  return (
    <>
    <ToastContainer />
    <div className="container mx-auto w-full my-32">
      <div className="flex flex-col md:flex-row mx-auto px-2 mt-10 text-xl">
        <div className="md:w-1/2">
          <h2 className="text-xl font-semibold mb-4">Get in Touch</h2>
          <p className="mb-4">Have questions or feedback? Reach out to us using the contact information below:</p>
          <ul className="mb-8">
            <li className="flex items-center mb-4">
              <svg className="w-6 h-6 mr-2" fill="currentColor" viewBox="0 0 24 24">
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8 0-4.41 3.59-8 8-8 4.41 0 8 3.59 8 8 0 4.41-3.59 8-8 8zm0-14c-3.31 0-6 2.69-6 6 0 1.63.64 3.13 1.67 4.24L12 19l4.33-4.76C17.36 13.13 18 11.63 18 10c0-3.31-2.69-6-6-6zm0 10c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z"></path>
              </svg>
              <span>Email: info@skyfalke.com</span>
            </li>
            <li className="flex items-center">
              <svg className="w-6 h-6 mr-2" fill="currentColor" viewBox="0 0 24 24">
                <path d="M12 1L3 5v14l9 4 9-4V5l-9-4zm0 2.7L19.6 6 12 8.8 4.4 6 12 3.3zM5 16V8.45l7 3.11 7-3.11V16l-7 3-7-3zm9-5h2v2h-2v-2zm-4 0h2v2H10v-2z"></path>
              </svg>
              <span>Phone: +254-717-797238</span>
            </li>
          </ul>
        </div>
        <div className="md:w-1/2">
          <h2 className="text-xl font-semibold mb-4">Send Us a Message</h2>
          <form onSubmit={handleCallToAction}>
            <div className="mb-4">
              <label className="block mb-2 font-semibold" htmlFor="name">Full Name</label>
              <input className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" type="text" value={name} onChange={(e)=>setName(e.target.value)} required />
              {errors.name && <span className="text-red-300">{errors.name}</span>}
            </div>
            <div className="mb-4">
              <label className="block mb-2 font-semibold" htmlFor="email">Email</label>
              <input className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" type="email" value={email} onChange={(e)=>setEmail(e.target.value)} required />
              {errors.email && <span className="text-red-300">{errors.email}</span>}
            </div>
            <div className="mb-4">
              <label className="block mb-2 font-semibold" htmlFor="name">Reason</label>
              <select className='w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500 ' value={reason} onChange={(e)=>setReason(e.target.value)} required>
                <option value="Enquiry">Enquiry</option>
                <option value="Our products & Solutions">Our products & Solutions</option>
                <option value="Sales">Sales</option>
                <option value="Report an isssue">Report an isssue</option>       
                <option value="Maintenance & Support">Maintenance & Support</option>
                <option value="Other">Other</option>
              </select>
              {errors.solution && <span className="text-red-300">{errors.solution}</span>}
            </div>
            <div className="mb-4">
              <label className="block mb-2 font-semibold" htmlFor="message">Message</label>
              <textarea className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" value={message} onChange={(e)=>setMessage(e.target.value)} rows="4" required></textarea>
              {errors.message && <span className="text-red-300">{errors.message}</span>}
            </div>
            <button className="px-6 py-2 bg-yellow-500 text-white rounded-md  focus:outline-none" type="submit"> {loading && (
                  <FaSpinner className='animate-spin mx-auto' />
              )}
              {!loading && 'Send Message'}</button>
          </form>
        </div>
      </div>
    </div>
    </>
  );
};

export default ContactUs;