import React from 'react';

const DocumentsPage = () => {
  const documents = [
    {
      title: 'Product Brochure',
      description: 'Learn more about our flagship products and their features.',
      file: require('../documentations/Product Brochure.pdf'),
    },
    // {
    //   title: 'User Manual',
    //   description: 'A comprehensive guide on how to use our products effectively.',
    //   file: 'user_manual.pdf',
    // },
    // {
    //   title: 'Case Studies',
    //   description: 'Explore real-world examples of how our solutions have helped businesses.',
    //   file: 'case_studies.pdf',
    // }
  ];

  return (
    <div className="container mx-auto w-full my-32">
      <h1 className="text-4xl font-bold text-center mt-10 mb-12">Documents and Resources</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {documents.map((document, index) => (
          <div key={index} className="bg-white rounded-lg shadow-lg p-8">
            <h2 className="text-2xl font-semibold mb-4">{document.title}</h2>
            <p className="text-gray-700 mb-6">{document.description}</p>
            <a href={document.file} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
              Download
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DocumentsPage;
