import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseURL } from '../../utils/constant';
import { format } from 'date-fns';

const CallToAction = () => {
  const [requests, setRequests] = useState([]);

  useEffect(() => {   
    const fetchCallToActions = async () => {
      try {
        const response = await axios.get(`${baseURL}/getCallToActions`); 
        setRequests(response.data.data);  
      } catch (error) {
        console.error('Error fetching call to action requests:', error);
      }
    };

    fetchCallToActions();
  }, []);

  return (
    <div className="p-6">
        <div className="my-6">
         <h1>Call To Action Requests</h1>
        </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {requests.map((item, index) => (
          <div className="bg-white p-4 shadow-md rounded-md">
            <h2 className="text-xl font-semibold">{item.name}</h2>
            <h4>{item.solution}</h4>
            <p>{item.message}</p>
            <div className="mt-2 text-sm text-gray-400">
                By {item.email} on {format(new Date(item.createdAt), 'MMMM dd, yyyy')}
            </div>
        </div>
        ))}
      </div>    
    </div>
  );
};

export default CallToAction;
