import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseURL } from '../utils/constant';
import { format } from 'date-fns';
import { PropagateLoader } from 'react-spinners';
import { css } from '@emotion/react';

const BlogPostPage = () => {
  const [blog, setBlog] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const url = window.location.href;
    const parts = url.split('/');
    const blogID = parts[parts.length - 1];

    async function fetchBlog() {
      try {
        const blogData = await axios.post(`${baseURL}/getblog`, { blogID });
        setBlog(blogData.data.data);
        setLoading(false);
      } catch (error) {
        alert('Error getting this document');
      }
    }

    if (blogID) {
      fetchBlog();
    }
  }, []);

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '80vh', // Adjust this value as needed
  };

  const override = css`
    display: block;
    margin: 0 auto;
  `;

  return (
    <div className="container mx-auto min-h-screen bg-gray-100 w-full my-32 text-xl">
      {loading ? (
        <div style={containerStyle}>
          <PropagateLoader css={override} color="#36D7B7" loading={loading} />
        </div>
      ) : (
        <>       
              <header className="bg-white shadow">
                <div className="container mx-auto px-4 py-6">
                  <h1 className="text-3xl font-bold text-gray-800">{blog.title}</h1>
                  <p className="text-sm text-gray-600">
                    Published on {format(new Date(blog.createdAt), 'MMMM dd, yyyy')} by {blog.author}
                  </p>
                </div>
              </header>
              <main className="container mx-auto px-4 py-8">
                <div className="max-w-3xl mx-auto">
                  {blog.thumbnail && (
                    <img
                      className="w-full h-64 object-cover rounded-md shadow-md mb-8"
                      src={require(`../webimages/${blog.thumbnail}`)}
                      alt={blog.title}
                    />
                  )}
                  <div
                    className="prose prose-lg text-gray-800"
                    dangerouslySetInnerHTML={{ __html: blog.content }}
                  />
                </div>
              </main>
            </>    
         )}
    </div>
  );
};

export default BlogPostPage;
