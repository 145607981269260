import React from 'react';

const AnalyticsPage = () => {
  return (
    <div className="container mx-auto my-32 w-full text-xl">
      <h1 className="text-4xl font-bold text-center mt-10 mb-12">Analytics and Insights</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="bg-white rounded-lg shadow-lg p-8">
          <h2 className="text-2xl font-semibold mb-4">Data-driven Decision Making</h2>
          <p className="text-gray-700 mb-6">
            Our analytics solutions provide powerful insights into your data, empowering you to make data-driven decisions for your business.
            With advanced data visualization and intuitive dashboards, you can uncover trends, identify patterns, and optimize your strategies.
          </p>
          <ul className="list-disc list-inside">
            <li>Real-time analytics</li>
            <li>Data exploration and visualization</li>
            <li>Key performance indicators (KPIs)</li>
            <li>Trend analysis</li>
            <li>Predictive analytics</li>
            <li>Customizable dashboards</li>
          </ul>
        </div>
        <div className="bg-white rounded-lg shadow-lg p-8">
          <h2 className="text-2xl font-semibold mb-4">Actionable Insights</h2>
          <p className="text-gray-700 mb-6">
            Our analytics platform goes beyond data analysis to provide actionable insights that drive your business forward.
            With the ability to identify bottlenecks, optimize processes, and discover new opportunities, you can stay ahead of the competition
            and maximize your business performance.
          </p>
          <ul className="list-disc list-inside">
            <li>Root cause analysis</li>
            <li>Segmentation and targeting</li>
            <li>A/B testing</li>
            <li>Conversion rate optimization</li>
            <li>Customer behavior analysis</li>
            <li>Market trends and predictions</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsPage;
