import React from 'react';
import { CheckIcon } from '@heroicons/react/outline';

const AllInOne = () => {
  return (
    <div name='platforms' className='w-full my-32'>
      <div className='max-w-[1240px] mx-auto'>
        <h2 className='text-3xl md:text-5xl text-center share-tech-regular'>What We Do</h2>
        <p className='text-2xl py-8 text-gray-500 text-center'>
          Our mission is to empower individuals and businesses with creative and innovative technology solutions that simplify and enhance their lives. The following are some of the services that we provide.
        </p>

        <div className='grid sm:grid-cols-2 lg:grid-cols-4 gap-4 pt-4'>

          <div className='flex'>
            <div>
              <CheckIcon className='w-7 mr-4 text-yellow-600' />
            </div>
            <div>
              <h3 className='font-bold text-lg'>Custom Software Development</h3>
              <p className='text-lg pt-2 pb-4'>
                We develop tailored software solutions to meet specific client requirements.
                 This include web applications, mobile apps, desktop software, enterprise systems, and specialized software for industries like healthcare, finance, or e-commerce.
              </p>
            </div>
          </div>       
          <div className='flex'>
            <div>
              <CheckIcon className='w-7 mr-4 text-yellow-600' />
            </div>
            <div>
              <h3 className='font-bold text-lg'>Software Consulting</h3>
              <p className='text-lg pt-2 pb-4'>
                We provide expert guidance and consulting services to clients on software architecture, technology stack selection, software solutions in the market e.g SAP ERP, project planning, and overall software development strategies. 
              </p>
            </div>
          </div>
          <div className='flex'>
            <div>
              <CheckIcon className='w-7 mr-4 text-yellow-600' />
            </div>
            <div>
              <h3 className='font-bold text-lg'>Software Maintenance & Support</h3>
              <p className='text-lg pt-2 pb-4'>
                We offer ongoing maintenance and support services for software applications, including bug fixes, updates, performance optimization, and security enhancements.
              </p>
            </div>
          </div>
          <div className='flex'>
            <div>
              <CheckIcon className='w-7 mr-4 text-yellow-600' />
            </div>
            <div>
              <h3 className='font-bold text-lg'>Quality Assurance and Testing</h3>
              <p className='text-lg pt-2 pb-4'>
                We conduct comprehensive testing of software applications to identify and fix bugs, ensure functionality, usability, and performance. Offer services like automated testing, regression testing, load testing, and user acceptance testing.
              </p>
            </div>
          </div>
          <div className='flex'>
            <div>
              <CheckIcon className='w-7 mr-4 text-yellow-600' />
            </div>
            <div>
              <h3 className='font-bold text-lg'>Cloud Solutions and Integration</h3>
              <p className='text-lg pt-2 pb-4'>
                We help clients leverage cloud technologies like Amazon Web Services (AWS), Microsoft Azure, or Google Cloud Platform (GCP). Offer services for cloud infrastructure setup, migration, scalability, and integration with existing systems.
              </p>
            </div>
          </div>
          <div className='flex'>
            <div>
              <CheckIcon className='w-7 mr-4 text-yellow-600' />
            </div>
            <div>
              <h3 className='font-bold text-lg'>Data Analytics and Business Intelligence</h3>
              <p className='text-lg pt-2 pb-4'>
                We assist businesses in extracting insights from their data through data analytics and visualization. Provide services for data mining, data modeling, business intelligence reporting, and predictive analytics.
              </p>
            </div>
          </div> 
          <div className='flex'>
            <div>
              <CheckIcon className='w-7 mr-4 text-yellow-600' />
            </div>
            <div>
              <h3 className='font-bold text-lg'>Cyber Security</h3>
              <p className='text-lg pt-2 pb-4'>
                  We offer Cyber Security services that protect businesses from cyber threats. Our experts use advanced tools to detect vulnerabilities, prevent breaches, and provide real-time protection.
              </p>
            </div>
          </div> 
          <div className='flex'>
            <div>
              <CheckIcon className='w-7 mr-4 text-yellow-600' />
            </div>
            <div>
              <h3 className='font-bold text-lg'>Graphics Design & Digital Design</h3>
              <p className='text-lg pt-2 pb-4'>   
                  We are specialized in crafting eye-catching and impactful designs for different actions, right from marketing, branding, product packaging, UI/UX, motion graphics to illustrations.
              </p>
            </div>
          </div> 
        </div>
      </div>
    </div>
  );
};

export default AllInOne;

