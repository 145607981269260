import React from 'react'

const Company = () => {
  return (
    <div name='platforms' className='w-full my-32'>
    <div className='max-w-[1240px] mx-auto px-2 mt-10 text-xl text-left'>
      <div className='mt-4'>
          <h3 className='font-bold text-4xl font-sans'>Our Company</h3>
          <div className='mt-4'>
              <div>
              <p>Welcome to SkyFalke!</p><br/>

                <p>At SkyFalke, we believe in the transformative power of technology. Our mission is <span className='italic'>to empower individuals and industries by providing cutting-edge technology solutions that enhance their day-to-day lives</span>. We understand that in today's fast-paced world, businesses need efficient and reliable technology to stay competitive and thrive. That's where we come in.

                <br/><br/>Our company philosophy is the driving force behind everything we do. We value <span className='font-semibold'>fun</span>, <span className='font-semibold'>honesty</span>, <span className='font-semibold'>innovation</span>, <span className='font-semibold'>collaboration</span>, and <span className='font-semibold'>coolness</span> in all aspects of our work. We believe that by embracing these core values, we can create an environment that fosters creativity and excellence.<br/><br/> We're not just another tech company; we're a team of passionate individuals dedicated to making a positive impact on the world through our work.

                Looking ahead, our vision is clear: <span className='italic'>to spearhead the transformation of industries worldwide through innovative technology solutions</span>. We aim to be at the forefront of digital innovation, helping businesses harness the full potential of technology. By empowering businesses, driving collaboration, and emphasizing ethical and sustainable impact, we strive to create a lasting legacy in the digital age.

                <br/><br/>At SkyFalke, we don't just provide technology solutions; we build partnerships. We work closely with our clients, understanding their unique challenges and objectives, and then designing tailored solutions to meet their needs. Our team of experts is committed to delivering excellence, ensuring that our clients receive the highest level of service and support.

                Whether you're a small business looking to streamline your operations or a large corporation aiming to innovate and stay ahead of the curve, SkyFalke is here to help.<br/><br/> Together, we can harness the power of technology and unlock new possibilities for your business.

                Join us on this exciting journey as we pave the way for a future powered by innovation, collaboration, and the endless possibilities of technology. Welcome to SkyFalke, where technology meets excellence.

                Stay tuned for the latest updates, industry insights, and innovative solutions as we continue to shape the future of technology.<br/><br/>

                <span className='mt-4 italic'>Let's soar to new heights together!</span> </p>
                </div>
          </div>
      </div>
  </div>
  </div>
  )
}

export default Company