// BlogPage.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; 
import axios from 'axios';
import { baseURL } from '../../utils/constant';
import BlogPost from './BlogPost';
import { format } from 'date-fns';


const BlogPage = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {   
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${baseURL}/getBlogs`); 
        setBlogs(response.data.data);  
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <div className="p-6">
        <div className="my-6">
          <Link to="../add-blog" className="bg-blue-500 text-white py-2 px-4 rounded">
            Add New Blog
          </Link>
        </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {blogs.map((blog, index) => (
          <BlogPost
            key={index}
            title={blog.title}           
            author={blog.author}
            date={format(new Date(blog.createdAt), 'MMMM dd, yyyy')}
          />
        ))}
      </div>    
    </div>
  );
};

export default BlogPage;
